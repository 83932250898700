import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const TeacherExamCreate = () => {
  // const [chapters, setChapters] = useState([]);
  const [question, setQuestion] = useState([]);
  const [exam_name, setExam_name] = useState('')
  // const [selectedChapter, setSelectedChapter] = useState("");

  //  const navigate = useNavigate();
  const notify = () => toast.success("Exam Created Successfully");

  useEffect(() => {
    // fetchChapters();
  }, []);

  // const fetchChapters = async () => {
  //   try {
  //     const response = await axios.get("${process.env.REACT_APP_NODE_API}/chapter/");
  //     setChapters(response.data);
  //   } catch (error) {
  //     console.error("Error fetching chapters:", error);
  //   }
  // };

  const handleChapterChange = (event) => {
    // setSelectedChapter(event.target.value);
  };

  useEffect(() => {
    fetchQuestion();
  }, []);

  const fetchQuestion = async () => {
    try {
      var url = localStorage.getItem('teacher_role') == 'Up to HSC' ? `${process.env.REACT_APP_NODE_API}/questions/school?class_id=${localStorage.getItem('class_id')}&subject_id=${localStorage.getItem('subject_id')}` : localStorage.getItem('teacher_role') == 'Undergraduate' ? `${process.env.REACT_APP_NODE_API}/questions/?course_id=${localStorage.getItem('course_id')}&subject_id=${localStorage.getItem('subject_id')}` : `${process.env.REACT_APP_NODE_API}/questions`

      const response = await axios.get(url);
      const result = response.data.reduce((obj, item) => {
        if (!obj.hasOwnProperty(item.stem_id)) {
          obj[item.stem_id] = [];
        }
        item.selected = false;
        obj[item.stem_id].push(item);
        return obj;
      }, []);
      setQuestion(result);

    } catch (error) {
      console.log("Error fetching Question", error);
    }
  };

  const addExam = e => {
    e.preventDefault()

    let data = {
      exam_category: 'blooms',
      exam_name,
      class_id: localStorage.getItem('teacher_role') == 'Up to HSC' ? localStorage.getItem('class_id') : 0,
      course_id: localStorage.getItem('teacher_role') == 'Undergraduate' ? localStorage.getItem('course_id') : 0,
      subject_id: localStorage.getItem('teacher_role') == 'Up to HSC' ? localStorage.getItem('subject_id') : 0,

    };

    axios.post(`${process.env.REACT_APP_NODE_API}/exam/school/add`, data).then(res => {
      question.map((item, index) => {
        if (item[0].selected == true) {
          axios.post(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/add`, {
            stem_id: item[0].stem_id,
            exam_id: res.data[0].id

          }).then(res => {
            toast.success('Submitted')
          })
        }

      })
    })
  }

  return (
    <div className="Admin h-screen">
      <Navbar />
      <ToastContainer />
      <ToastContainer />
      <div className="flex flex-col min-h-screen p-5">
        <form onSubmit={addExam} className="w-full mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          {/* <div className="mb-5">
            <h3 className="text-2xl font-semibold text-center text-blue-700 mb-20">
              CREATE EXAM
            </h3>
          </div>
          <div className="mb-4">
            <label
              htmlFor="chapter"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Select Question Chapter:
            </label>
            <select
              id="chapter"
              value={selectedChapter}
              onChange={handleChapterChange}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select a chapter</option>
              {chapters.map((chapter) => (
                <option key={chapter.id} value={chapter.id}>
                  {chapter.chapter_name}
                </option>
              ))}
            </select>
          </div> */}
          <input
            type="text"
            name="exam_name"
            value={exam_name}
            onChange={(e) => setExam_name(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter Exam Name"
            required
          />
          <div className="mb-4">
            <div className="bg-white rounded shadow-lg p-6 mt-6">
              {question.map((entry, index) => (
                <div
                  className="bg-white rounded shadow-lg p-6 mt-6"
                  key={index}
                >
                  <div className="flex items-center justify-between mb-10">
                    <p className="ml-2 text-2xl text-gray-600 font-bold uppercase">
                      {entry[0].stem_desc}
                    </p>
                    <input
                      id={`link-checkbox-${index}`}
                      value={entry[0].selected}
                      onChange={e => {
                        let clone = question
                        clone[index][0].selected = !entry[0].selected
                        console.log(question);
                        setQuestion(clone)
                      }}
                      type="checkbox"
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </div>
                  <div className="ml-5 mt-3 space-y-3">
                    {entry.map((item, itemIndex) => (
                      <p
                        key={itemIndex}
                        className="text-gray-700 mx-5 bg-gray-100 p-4 rounded shadow-sm text-xl"
                      >
                        ({item.ques_point}) {item.ques_desc} [{item.marks}{" "}
                        marks] [{item.domain}]
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button

            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          >
            Create
          </button>
        </form>
      </div>
    </div>
  );
};

export default TeacherExamCreate;
