import React from 'react'
import Navbar from '../components/Navbar'
import { useNavigate } from 'react-router-dom';

const StudentGivenExam = () => {
    const navigate = useNavigate();
  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <div className=" flex justify-center align-middle items-center mt-6">
        <div className="flex flex-col min-h-screen">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-10 py-10"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigate("/Bloom_Exam-Given_Page")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  BLOOM QUIZ 1
                </h5>

                <p className="font-semibold">Chapter 1 (BLOOM)</p>
                <p className="font-semibold">BLOOMS</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-10 py-10"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigate("/Bloom_Exam-Given_Page")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  BLOOM QUIZ 2
                </h5>

                <p className="font-semibold">Chapter 2 (BLOOM)</p>
                <p className="font-semibold">BLOOMS</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-10 py-10"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigate("/MCQ_Exam-Given_Page")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  MCQ QUIZ 1
                </h5>

                <p className="font-semibold">Chapter 1 (MCQ)</p>
                <p className="font-semibold">MCQ</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-10 py-10"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigate("/MCQ_Exam-Given_Page")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  MCQ QUIZ 2
                </h5>

                <p className="font-semibold">Chapter 2 (MCQ)</p>
                <p className="font-semibold">MCQ</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentGivenExam
