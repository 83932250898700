import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from '../components/Navbar';

const GiveExam = () => {
      useEffect(() => {
        AOS.init({ duration: "1000" });
      });
      const navigation = useNavigate();
  return (
    <div className="Admin">
      <Navbar />
      <div className=" flex justify-center align-middle items-center mt-6">
        <div className="flex flex-col min-h-screen">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
            {/*<div
              className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
                src={Student}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  Student Enroll
                </h5>

                <p class="mb-3 font-normal">
                  In here We can enroll student and check their details
                </p>
                <button
                  onClick={() => navigation("/Student_Enroll_Page")}
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Enroll Student
                </button>
              </div>
            </div>

            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
              data-aos="fade-left"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
                src={Teacher}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  Teacher Enroll
                </h5>

                <p class="mb-3 font-normal">
                  In this we can assign teacher and enter their details
                </p>
                <button
                  onClick={() => navigation("/Teacher_Assign_Page")}
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Enroll Teacher
                </button>
              </div>
  </div>*/}

            {/*        <div
                  onClick={() => navigation("/add-job")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Job Positions
                  </h2>
</div> */}

            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Question_Type")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  Web Development
                </h5>

                <p className="font-normal">Course Code: 201</p>
                <p className="font-normal">Batch: 8</p>
              </div>
            </div>

            {/*        <div
                  onClick={() => navigation("/add-skill")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Set Competency
                  </h2>
</div> */}
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Question_Type")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">Laravel</h5>

                <p className="font-normal">Course Code: 230</p>
                <p className="font-normal">Batch: 8</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Question_Type")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  Graphics Design
                </h5>

                <p className="font-normal">Course Code: 203</p>
                <p className="font-normal">Batch: 8</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Question_Type")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  Finance and Management
                </h5>

                <p className="font-normal">Course Code: 402</p>
                <p className="font-normal">Batch: 8</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiveExam
