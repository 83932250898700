import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";

const TeacherEnroll = () => {
  const notify = () =>
    toast.success("Teacher Enrolled Successfully");
  const [batch, setBatch] = useState("");
  const [classes, setClasses] = useState("");
  const [institute, setInstitute] = useState("");
  const [institute_type, setInstitute_Type] = useState("");

  useEffect(() => {
    console.log("Component mounted");
  }, []);

  const submit = (e) => {
    e.preventDefault();
    notify();
  };

  const columns = [
    { name: "Teacher Name", selector: (row) => row.name, sortable: true },
    { name: "Teacher Email", selector: (row) => row.email, sortable: true },
    { name: "Class", selector: (row) => row.teacher_class, sortable: true },
    {
      name: "Institute Type",
      selector: (row) => row.institute_type,
      sortable: true,
    },
    {
      name: "Organization Name",
      selector: (row) => row.organize_name,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          onClick={notify}
          className="text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-md px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
        >
          ENROLL
        </button>
      ),
    },
  ];

  const data = [
    {
      name: "test",
      email: "test@gmail.com",
      teacher_class: "Class 1",
      institute_type: "School",
      organize_name: "National Ideal School",
    },
  ];

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <ToastContainer />
      <div className="grid place-items-center bg-gray-100 py-10 px-4">
        <div className="p-5 text-center">
          <h1 className="font-poppins text-4xl font-semibold text-blue-700">
            Assign Teacher
          </h1>
        </div>
        <form
          className="max-w-lg bg-white shadow-lg rounded-lg p-6"
          onSubmit={submit}
        >
          <div className="grid grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Batch
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                value={batch}
                onChange={(e) => setBatch(e.target.value)}
              >
                <option value="">Select Batch</option>
                <option>Batch 1</option>
                <option>Batch 2</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Institute Type
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                value={institute_type}
                onChange={(e) => setInstitute_Type(e.target.value)}
              >
                <option value="">Select Type</option>
                <option>School</option>
                <option>College</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Class
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                value={classes}
                onChange={(e) => setClasses(e.target.value)}
              >
                <option value="">Select Class</option>
                <option>Class 1</option>
                <option>Class 2</option>
              </select>
            </div>
            <div className="flex flex-col">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Name of Organization
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                value={institute}
                onChange={(e) => setInstitute(e.target.value)}
              >
                <option value="">Select Institution</option>
                <option>National Ideal School</option>
                <option>Faizur Rahman Ideal School</option>
              </select>
            </div>
          </div>
          <div className="mt-6 text-center">
            <button
              className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-md px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="submit"
            >
              Enroll
            </button>
          </div>
        </form>
        <div className="container mt-10">
          <DataTable
            className="z-0 mx-auto bg-white rounded-lg shadow-md"
            columns={columns}
            data={data}
            pagination
          />
        </div>
      </div>
    </div>
  );
};

export default TeacherEnroll;
