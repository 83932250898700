import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import img from "../assets/Untitled design (4).png";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";
import { HiMail } from "react-icons/hi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import logo from '../assets/1b828ec0-0e44-4a57-a634-d37bd797e665.jfif'

const Login = () => {
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [butonDisabled, setButtodDisabled] = useState(false)
  const navigate = useNavigate();

  const notify = () => toast.success("Login is Successfull");

  const submit = (e) => {
    e.preventDefault();

    // setButtodDisabled(true)
    axios
      .post(`${process.env.REACT_APP_NODE_API}/user/login_with_phone_number`, {
        phone,
        password,
      })
      .then((res) => {
        localStorage.setItem("role", res.data.role);
        localStorage.setItem("user_id", res.data.user_id);
        localStorage.setItem("token", res.data.token);
        setButtodDisabled(false)
        if (res.data.role == 'admin') {
          navigate('/Super_Admin_Page')
        }
        if (res.data.role == 'HR admin') {
          navigate('/Hr_admin')
        }
        if (res.data.role == 'Student') {
          axios.get(`${process.env.REACT_APP_NODE_API}/student/?user_id=${res.data['user_id']}`)
            .then(res => {
              if (res.data[0].student_role == 'Up to HSC') {
                localStorage.setItem('class_id', res.data[0].class_id)

              } else if (res.data[0].student_role == 'Undergraduate') {
                localStorage.setItem('course_id', res.data[0].course_id)
              }

              localStorage.setItem('student_role', res.data[0].student_role)
              navigate('/Student_Dashboard')
            })

        }
        if (res.data.role == 'Teacher') {
          axios.get(`${process.env.REACT_APP_NODE_API}/teacher/?user_id=${res.data['user_id']}`)
            .then(res => {
              if (res.data[0].teacher_role == 'Up to HSC') {
                localStorage.setItem('class_id', res.data[0].class_id)
                localStorage.setItem('subject_id', res.data[0].subject_id)

              } else if (res.data[0].teacher_role == 'Undergraduate') {
                localStorage.setItem('course_id', res.data[0].course_id)
              }

              localStorage.setItem('teacher_role', res.data[0].teacher_role)

              navigate('/Teacher_Dashboard')
            })
        }
      })
      .catch((err) => {
        toast(err.response.data.msg);
        console.log(err);
      });
  };

  return (
    <div className="bg-[#F8F8F8] h-[100vh] flex">
      <ToastContainer />
      <div className="container m-auto h-[80vh] items-center bg-[#FFFFFF] rounded-md shadow-md">
        <div className="lg:grid grid-cols-2 gap-5 items-center">
          <div className="h-[70vh] p-4 lg:block hidden">
            <div className="bg-[#F6F6F6] rounded-sm text-center items-center h-[75vh]">
              <img src={img} className="w-full h-full" alt="" />
            </div>
          </div>
          <div className="text-center">
            <div className="flex justify-center">
              <img src={logo} alt="" className='w-60 h-30 justify-center' />
            </div>
            <div className="text-2xl mt-3">
              <h3 className="font-bold">Login</h3>
              <div className="flex items-center justify-center mt-5">
                <form className="flex w-80 flex-col gap-6">
                  <div>
                    <div className="mb-2 block">
                      <Label htmlFor="email1" value="Phone Number" />
                    </div>
                    <input
                      id="email1"
                      type="text"
                      placeholder="Phone Number"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <div className=" block">
                      <Label htmlFor="password" value="Password" />
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        placeholder="Password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                      <AiOutlineEye
                        className={`fas fa-eye ${showPassword ? "hidden" : ""
                          } text-gray-400`}
                        onClick={() => setShowPassword(true)}
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                      <AiOutlineEyeInvisible
                        className={`fas fa-eye-slash ${showPassword ? "" : "hidden"
                          } text-gray-400`}
                        onClick={() => setShowPassword(false)}
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    className="bg-blue-500 hover:bg-blue-900"
                    type="submit"
                    onClick={submit}
                    disabled={butonDisabled}
                  >
                    {/* <Link to="/" style={{ color: "white", fontSize: "14px" }}> */}
                    Login
                    {/* </Link> */}
                  </Button>
                </form>
              </div>
              <div className="text-sm mt-3">
                <p>
                  If you don't have and account then{" "}
                  <Link
                    className="font-bold"
                    to="/register"
                    style={{ color: "blue", fontSize: "14px" }}
                  >
                    <span
                      onClick={(e) => {
                        navigate("/register");
                      }}
                    >
                      Register
                    </span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
{/* <div className='h-screen flex justify-center items-center'>
        <ToastContainer />
        <div>
        <div className='p-5 bg-gray-500 text-green-300'>
                    <h1 className='font-poppins text-xl'>Login</h1>
                </div>
                <div className='flex flex-col items-start px-6 pt-3'>
                    <label className='pb-2 text-lg'>Email:</label>
                    <input value={email} onChange={e => setEmail(e.target.value)} className='shadow appearance-none border rounded w-[90%] py-2 px-3 focus:outline-none focus:shadow-outline focus:ring-4 focus:ring-blue-600 mb-' />
                </div>
                <div className='flex flex-col items-start px-6 pt-3'>
                    <label className='pb-2 text-lg'>Password:</label>
                    <input type='password' value={password} onChange={e => setPassword(e.target.value)} className='shadow appearance-none border rounded w-[90%] py-2 px-3 focus:outline-none focus:shadow-outline focus:ring-4 focus:ring-blue-600 mb-' />
                </div>
                <div className='grid place-items-center mt-4 mr-7'>
                    <button onClick={submit} className='bg-slate-500 focus:ring-4 focus:ring-slate-700 text-white px-5 py-3 rounded text-lg'>Submit</button>
                </div>
                <div>
                    <p>Don't have an account? <span onClick={e => {
                        navigate('/register')
                    }} className='text-blue-400 cursor-pointer'>Register</span></p>
                </div>
        </div>
                </div> */}


export default Login