import React from 'react'
import Navbar from '../components/Navbar'
import DataTable from 'react-data-table-component';

const StudentExamDate = () => {
     const columns = [
       {
         name: "STUDENT NAME",
         selector: (row) => row.student_name,
         sortable: true,
       },
       {
         name: "COURSE CODE",
         selector: (row) => row.code,
         sortable: true,
       },
       {
        name: "EXAM DATE",
        selector: (row) => row.date,
        sortable: true,
       }
     ];

     const data = [
       {
         student_name: "Atif Aziz",
         code: "CSE-402",
         date: "23/5/23",
       },
       {
         student_name: "Atif Aziz",
         code: "CSE-402",
         date: "23/5/23",
       },
       {
         student_name: "Atif Aziz",
         code: "CSE-402",
         date: "23/5/23",
       },
       {
         student_name: "Atif Aziz",
         code: "CSE-402",
         date: "23/5/23",
       },
       {
         student_name: "Atif Aziz",
         code: "CSE-402",
         date: "23/5/23",
       },
     ];

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <div className="container mx-auto p-5">
        <div className="bg-white rounded shadow-lg p-6 mt-6">
          <h2 className="text-3xl font-bold text-center text-blue-600 mb-6">
            Exam Date
          </h2>
          <div className="mb-4 flex justify-center items-center">
            <div className="relative w-full">
            </div>
          </div>
          <DataTable
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            pointerOnHover
            className="border border-gray-300 rounded"
            customStyles={{
              rows: {
                style: {
                  minHeight: "72px",
                },
              },
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#f7fafc",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default StudentExamDate
