import React from "react";
import { AiOutlineUser, AiOutlineStar } from "react-icons/ai";

const Course = ({ image, category, title, participants, rating, price }) => {
  return (
    <div className="p-2 shadow-lg bg-white rounded-md w-full sm:w-[20rem] lg:w-[25rem]">
      <img src={image} alt="" className="h-40 w-full object-cover rounded-md" />
      <div className="mt-2 text-xs text-amber-600">{category}</div>
      <div className="text-sm mt-2 font-bold">{title}</div>
      <div className="flex items-center justify-between mt-2">
        <div className="flex items-center gap-2">
          <div className="bg-Solitude p-1 rounded-full">
            <AiOutlineUser className="text-amber-600" />
          </div>
          <div className="text-sm font-bold">{participants}</div>
        </div>
        <div className="flex items-center gap-2">
          <div className="bg-Solitude p-1 rounded-full">
            <AiOutlineStar className="text-yellow" />
          </div>
          <div className="text-sm font-bold">{rating}</div>
        </div>
      </div>
      <div className="text-sm font-bold mt-2">{price} TK</div>
    </div>
  );
};

export default Course;
