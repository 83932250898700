import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const StudentAddMarksOnBloom = () => {
  const [answers, setAnswers] = useState([])
  const [exams, setExams] = useState([])
  const [exam_id, setExam_id] = useState('')
  useEffect(() => {
    let courseorclassid = localStorage.getItem('student_role') == 'Up to HSC' ? `subject_id=${localStorage.getItem('subject_id')}` : localStorage.getItem('student_role') == 'Undergraduate' ? `course_id=${localStorage.getItem('course_id')}` : ''

    axios.get(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/exam_name/?${courseorclassid}`)
      .then(res => {
        setExams(res.data);
      })
  }, []);

  const fetchAnswers = async () => {
    try {


      let courseorclassid = localStorage.getItem('teacher_role') == 'Up to HSC' ? `class_id=${localStorage.getItem('class_id')}&subject_id=${localStorage.getItem('subject_id')}&exam_id=${exam_id}` : localStorage.getItem('teacher_role') == 'Undergraduate' ? `course_id=${localStorage.getItem('course_id')}&exam_id=${exam_id}` : ''

      const response = await axios.get(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/answers?${courseorclassid}`);
      const data = await response.data
      console.log(data);



      const result = response.data.reduce((obj, item) => {
        if (!obj.hasOwnProperty(item.stem_id)) {
          obj[item.stem_id] = [];
        }
        // console.log(existingResult[item.ques_id].answer);
        item.given_marks = item.marks != null ? item.marks : ''
        obj[item.stem_id].push(item);

        return obj;
      }, []);
      console.log(result);
      setAnswers(result);



    } catch (error) {
      console.log("Error fetching Question", error);
    }
  };



  const addMarks = e => {
    e.preventDefault()

    answers.map((answer, index) => {
      answer.map((k, i2) => {
        if (k.given_marks != '') {
          axios.put(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/marks/add?id=${k['answer_id']}`, {
            marks: k['given_marks']
          })
            .then(res => {

            })
        }
      })
    })

    toast.success('Submitted')
  }


  return (
    <div className="Admin min-h-screen bg-gray-100">
      <Navbar />
      <ToastContainer />
      <div className="container mx-auto px-4 py-8">
        <div className=" flex mb-4">
          <select
            id="chapter"
            value={exam_id}
            onChange={e => setExam_id(e.target.value)}
            className="w-full mr-4 p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select Exam</option>
            {exams.map((exam) => (
              <option key={exam.id} value={exam.exam_id}>
                {exam.exam_name}
              </option>
            ))}
          </select>
          <button
            onClick={fetchAnswers}
            className="text-white bg-blue-500 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-lg transition duration-200 ease-in-out"
          >
            Search
          </button>
        </div>
        {
          answers.map((item, index1) => (
            <div className="bg-white rounded-md shadow-md p-6">
              <h1 className="text-center text-2xl text-md font-bold mb-8 text-gray-800">Name: {item[0].name}</h1>

              <p className="text-md font-semibold mb-8 text-gray-800">
                {item[0].stem_desc}
              </p>
              {
                item.map((element, index) => (
                  <div className="border border-slate-500 rounded-md p-6 mb-8">
                    <h2 className="text-md font-normal mb-4">
                      <span className="font-bold">({element.ques_point})</span> {element.ques_desc} [{element.ques_marks} marks] [{element.domain}]
                    </h2>
                    <p className="text-sm mb-6 text-gray-800">
                      Response Answer: {element.answer}
                    </p>
                    <div className="flex items-center mb-4">
                      <label htmlFor="give-mark-a" className="mr-2 text-gray-700">
                        Give Mark:
                      </label>
                      <input
                        // value={element.given_marks}
                        defaultValue={element.given_marks}
                        onChange={e => {
                          setAnswers(prev => {

                            prev[index1][index].given_marks = e.target.value
                            console.log(prev);
                            return prev
                          })

                        }}
                        type="text"
                        id="give-mark-a"
                        className="border border-blue-300 rounded-md px-3 py-1 focus:outline-none focus:ring focus:border-blue-300"
                      />
                    </div>
                  </div>
                ))
              }

            </div>
          ))
        }

        <button
          onClick={addMarks}
          class="text-white mt-10 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default StudentAddMarksOnBloom;
