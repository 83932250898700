import React from 'react'
import Navbar from '../components/Navbar'
import { useNavigate } from 'react-router-dom';
import Teacher from '../assets/118097650-the-students-give-their-teachers-homework (2).jpg';
import Enroll from '../assets/iStock-1066852584.png'

const CourseAdmin = () => {
    const navigation = useNavigate();
  return (
    <div className="Admin">
      <Navbar />
      <div className="flex flex-col min-h-screen justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={Teacher}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Assign Teacher
              </h5>

              <p class="mb-3 font-normal">In here we can assign teacher</p>
              <button
                onClick={() => navigation("/Assign_Teacher")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Assign
              </button>
            </div>
          </div>

          <div
            className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={Enroll}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Enroll Student Course
              </h5>

              <p class="mb-3 font-normal">
                In this we can assign student into the course
              </p>
              <button
                onClick={() => navigation("/Enroll_Student_Course")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Assign
              </button>
            </div>
          </div>

          {/*        <div
                  onClick={() => navigation("/add-job")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Job Positions
                  </h2>
</div> */}

          {/*        <div
                  onClick={() => navigation("/add-skill")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Set Competency
                  </h2>
</div> */}
        </div>
      </div>
    </div>
  );
}

export default CourseAdmin
