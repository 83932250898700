import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../components/Navbar";
import Modal from "react-modal";
import { FaTimes, FaCheckCircle } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import "tailwindcss/tailwind.css";
import "./modal.css";

const EnrollStudentCourse = () => {
  const notify = () => toast.success("Course Enrolled Successfully");


  useEffect(() => {
    console.log("Component Mounted");
  }, []);

  const submit = (e) => {
    e.preventDefault();

    notify();
  };

  const columns = [
    {
      name: "Student Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Student Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Class",
      selector: (row) => row.classes,
      sortable: true,
    },
    {
      name: "Organization Name",
      selector: (row) => row.organize_name,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          // onClick={() => showModal(row)}
          className="text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-md px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Enroll
        </button>
      ),
    },
  ];

  const data = [
    {
      name: "Test Student",
      email: "test@test.com",
      phone: "0135672418237",
      classes: "Class 1",
      organize_name: "National Ideal School",
    },
    // Add more data as needed
  ];


  return (
    <div className="Admin bg-gray-100 min-h-screen">
      <Navbar />
      <div className="container mx-auto py-8">
        <ToastContainer />
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-800">
            Enroll Student Course
          </h1>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <DataTable
            className="mx-auto"
            columns={columns}
            data={data}
            fixedHeader
            pagination
            highlightOnHover
            pointerOnHover
            customStyles={{
              headCells: {
                style: {
                  backgroundColor: "#f7fafc",
                },
              },
            }}
          />
        
        </div>
      </div>
    </div>
  );
};

export default EnrollStudentCourse;
