import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";

const AddMcqQuestion = () => {
  const [questions, setQuestions] = useState([
    {
      questionText: "",
      options: ["", "", "", ""],
      correctOption: "",
    },
  ]);

  const handleQuestionChange = (index, event) => {
    const newQuestions = [...questions];
    newQuestions[index].questionText = event.target.value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (qIndex, oIndex, event) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex] = event.target.value;
    setQuestions(newQuestions);
  };

  const handleCorrectOptionChange = (qIndex, event) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].correctOption = event.target.value;
    setQuestions(newQuestions);
  };

  const handleAddMore = () => {
    setQuestions([
      ...questions,
      {
        questionText: "",
        options: ["", "", "", ""],
        correctOption: "",
      },
    ]);
  };

  const handleRemove = (indexToRemove) => {
    setQuestions(questions.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = () => {
    console.log("Submitted Questions: ", questions);
  };

  const [chapters, setChapters] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState("");

  useEffect(() => {
    fetchChapters();
  }, []);

  const fetchChapters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_API}/chapter/`
      );
      setChapters(response.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const handleChapterChange = (event) => {
    setSelectedChapter(event.target.value);
  };

  //backend part
  //   const handleSubmit = () => {
  //     const questionsData = questions.map((question) => ({
  //       questionText: question.questionText,
  //       options: question.options,
  //       correctOption: question.correctOption,
  //     }));

  //     fetch("/api/questions", {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ questions: questionsData }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => console.log(data))
  //       .catch((error) => console.error(error));
  //   };

  return (
    <div className="Admin bg-gray-100 min-h-screen">
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded shadow-lg p-6 mt-6">
          <h2 className="text-3xl font-semibold text-center text-blue-700 mb-8">
            ADD QUESTION
          </h2>
          <div className="mb-4">
            <label
              htmlFor="chapter"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Select Question Chapter:
            </label>
            <select
              id="chapter"
              value={selectedChapter}
              onChange={handleChapterChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">Select a chapter</option>
              {chapters.map((chapter) => (
                <option key={chapter.id} value={chapter.chapter_name}>
                  {chapter.chapter_name}
                </option>
              ))}
            </select>
          </div>
          {questions.map((question, qIndex) => (
            <div key={qIndex} className="mb-8">
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Question Text:
                </label>
                <input
                  type="text"
                  placeholder="Enter Question"
                  onChange={(e) => handleQuestionChange(qIndex, e)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                />
              </div>
              {question.options.map((option, oIndex) => (
                <div key={oIndex} className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Option {String.fromCharCode(97 + oIndex)}:
                  </label>
                  <input
                    type="text"
                    placeholder="Give Option"
                    value={option}
                    onChange={(e) => handleOptionChange(qIndex, oIndex, e)}
                    className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                  />
                </div>
              ))}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Correct Option:
                </label>
                {question.options.map((_, oIndex) => (
                  <div key={oIndex} className="mb-2 mx-6">
                    <input
                      type="radio"
                      id={`correctOption-${qIndex}-${oIndex}`}
                      name={`correctOption-${qIndex}`}
                      value={String.fromCharCode(97 + oIndex)}
                      checked={
                        question.correctOption ===
                        String.fromCharCode(97 + oIndex)
                      }
                      onChange={(e) => handleCorrectOptionChange(qIndex, e)}
                      className="mr-2"
                    />
                    <label htmlFor={`correctOption-${qIndex}-${oIndex}`}>
                      Option {String.fromCharCode(97 + oIndex)}
                    </label>
                  </div>
                ))}
              </div>
              {qIndex > 0 && (
                <button
                  onClick={() => handleRemove(qIndex)}
                  className="text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-3 py-1.5 mb-2 shadow-lg transition duration-200 ease-in-out"
                >
                  Remove
                </button>
              )}
            </div>
          ))}
          <button
            onClick={handleAddMore}
            className="text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 mr-2 mb-2 shadow-lg transition duration-200 ease-in-out"
          >
            Add More
          </button>
          <button
            onClick={handleSubmit}
            className="text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-3 py-1.5 shadow-lg transition duration-200 ease-in-out"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddMcqQuestion;
