import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import axios from "axios";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const StudentProgression = () => {
  const [matchRate, setMatchRate] = useState(75);

  const [totalMarks, setTotalMarks] = useState({
    "Stdent ID": 0,
    Remembering: 0,
    Understanding: 0,
    Applying: 0,
    Analyzing: 0,
    Evaluation: 0,
    Creating: 0
  });

  const [issues, setIssues] = useState({
    Creating: 1,
    Evaluation: 4,
    Analyzing: 0,
    Applying: 4,
    Understanding: 5,
    Remembering: 2,
  });



  const renderIssueIcon = (count) => {
    if (count === 0) {
      return <CheckCircleIcon className="h-5 w-5 text-green-500" />;
    } else if (count < 3) {
      return <ExclamationCircleIcon className="h-5 w-5 text-yellow-500" />;
    } else {
      return <XCircleIcon className="h-5 w-5 text-red-500" />;
    }
  };

  const [barData, setBarData] = useState({});


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/answers?student_id=${localStorage.getItem('user_id')}`)
      .then((res) => {
        let totalCopy = totalMarks
        res.data.forEach(i => {

          totalCopy[i.domain] += i.marks != null ? parseInt(i.marks) : 0
          totalCopy['Stdent ID'] = parseInt(localStorage.getItem('user_id'))

        });

        setTotalMarks(totalCopy)

        console.log(totalCopy);

        axios.post(`http://68.178.163.174:5500/predict`, {
          ...totalCopy, headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(res => {
            var career = res.data['career_probability'].split('     ')
            var firstData = { 'name': career[0].substring(0, career[0].indexOf('(')), 'percentage': parseInt(career[0].substring(career[0].indexOf('(') + 1, career[0].indexOf('%'))) };
            var secondData = { 'name': career[1].substring(0, career[1].indexOf('(')), 'percentage': parseInt(career[1].substring(career[1].indexOf('(') + 1, career[1].indexOf('%'))) };
            var thirdData = { 'name': career[2].substring(0, career[2].indexOf('(')), 'percentage': parseInt(career[2].substring(career[2].indexOf('(') + 1, career[2].indexOf('%'))) };
            setBarData({
              labels: [firstData.name, secondData.name, thirdData.name],
              datasets: [
                {
                  label: "Career Indicating",
                  data: [firstData.percentage, secondData.percentage, thirdData.percentage],

                  backgroundColor: "rgba(75, 192, 192, 0.2)",
                  borderColor: "rgba(75, 192, 192, 1)",
                  borderWidth: 1,
                },
              ],
            })
          })

      })
  }, [])


  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      // title: {
      //   display: true,
      //   text: "Doctor",
      // },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="Admin">
      <Navbar />
      <div className="min-h-screen bg-gray-100 p-6">
        <header className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-3xl font-bold text-gray-800">Hello, Student</h1>
            <p className="text-gray-500">Monday, 8 July 2024</p>
          </div>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-800">Total Exams</h2>
            <p className="text-2xl font-bold text-green-500">1</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-800">Exam Time</h2>
            <p className="text-2xl font-bold text-green-500">30 Mins</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold text-gray-800">
              Course Taken
            </h2>
            <p className="text-2xl font-bold text-green-500">1</p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col items-center justify-center gap-6 p-6 bg-white rounded-lg shadow-lg w-full mb-5">
            <div className="relative w-40 h-40 rounded-full">
              <div className="absolute inset-0 bg-gradient-to-r from-green-400 to-green-600 rounded-full">
                <div className="absolute inset-1 bg-white rounded-full flex items-center justify-center">
                  <span className="text-3xl font-bold text-green-600">
                    {matchRate}%
                  </span>
                </div>
              </div>
            </div>

            <h2 className="text-2xl font-bold">Match Rate</h2>
            <p className="text-md text-gray-500">75%</p>
            <p className="text-md text-green-500 font-bold">Architect</p>

            <div className="w-full mb-5">
              <p className="text-md text-gray-500 mt-2 text-center">
                75%+ match rate is recommended
              </p>
            </div>

            <div className="w-full">
              {Object.entries(issues).map(([key, count]) => (
                <div
                  key={key}
                  className="flex items-center gap-2 text-sm bg-gray-50 p-2 rounded-lg shadow-sm"
                >
                  {renderIssueIcon(count)}
                  <span className="font-bold capitalize">{key}</span>
                  <span className="ml-auto">{`${count} mistake${count > 1 ? "s" : ""
                    } percent`}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4 text-center text-gray-800">
              Career Path
            </h2>
            <div className="overflow-x-auto">
              {Object.keys(barData) != 0 && (
                <Bar data={barData} options={options} />
              )}
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md mt-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">
            Student Report
          </h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Subject
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Lowest Marks
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Highest Marks
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total Marks
                  </th>
                  <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Career
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="px-6 py-4 whitespace-nowrap">24/5/24</td>
                  <td className="px-6 py-4 whitespace-nowrap">Physics</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="badge-red">Creating</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="badge-green">Remembering</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">77 Marks</td>
                  <td className="px-6 py-4 whitespace-nowrap">Architect</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentProgression;
