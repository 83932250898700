import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const AnswerBloomQuestion = () => {

  const [question, setQuestion] = useState([]);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    fetchQuestion();
  }, []);

  const fetchQuestion = async () => {
    try {
      var url = localStorage.getItem('teacher_role') == 'Up to HSC' ? `${process.env.REACT_APP_NODE_API}/questions/school?class_id=${localStorage.getItem('class_id')}&subject_id=${localStorage.getItem('subject_id')}` : localStorage.getItem('teacher_role') == 'Undergraduate' ? `${process.env.REACT_APP_NODE_API}/questions/?course_id=${localStorage.getItem('course_id')}` : `${process.env.REACT_APP_NODE_API}/questions`
      console.log(url);
      const response = await axios.get(url);
      const result = response.data.reduce((obj, item) => {
        if (!obj.hasOwnProperty(item.stem_id)) {
          obj[item.stem_id] = [];
        }

        obj[item.stem_id].push(item);

        return obj;
      }, []);
      console.log(result);
      result.map((entry, index) => {
        console.log(entry[0].stem_desc);
      })


      setQuestion(result);
    } catch (error) {
      console.log("Error fetching Question", error);
    }
  }

  const navigate = useNavigate();

  return (
    <div className="Admin bg-gray-50 min-h-screen">
      <Navbar />
      <div className="flex flex-col min-h-screen p-5">
        <div className="flex justify-end mb-5 mt-5">
          <button
            className="text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-lg transition duration-200 ease-in-out"
            onClick={() => navigate("/AddBloomQuestion")}
          >
            Add Bloom Question
          </button>
        </div>
        <div className="bg-white rounded shadow-lg p-6 mt-6">
          <h2 className="text-3xl font-semibold text-center text-blue-700 mb-8">
            {localStorage.getItem("chapter_name")}
          </h2>
          {question.map((entry, index) => (
            <div className="bg-white rounded shadow-lg p-6 mt-6">
              <div key={index} className="mb-10">
                <p className="text-2xl text-gray-600 font-bold uppercase">
                  {entry[0].stem_desc}
                </p>
              </div>
              <div key={index} className="mb-10">
                <div className="ml-5 mt-3 space-y-3">
                  {entry.map((item, index) => (
                    <p className="text-gray-700 mx-5 bg-gray-100 p-4 rounded shadow-sm text-xl">
                      ({item.ques_point}) {item.ques_desc} [{item.marks}{" "}
                      marks] [{item.domain}]
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnswerBloomQuestion;
