import React, { useEffect } from 'react'
import Header from '../Header';
import MyFooter from '../MyFooter';
import hero from "../../assets/our-progms-csit-removebg-preview.png";
import blog_1 from "../../assets/courses1.jpg";
import blog_2 from "../../assets/courses4.jpg";
import blog_3 from "../../assets/courses7.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaCode, FaLaptopCode, FaMoneyCheck, FaPalette, FaTruck } from 'react-icons/fa';
import { motion } from "framer-motion";
import OurMission from './OurMission';
import { AiOutlineLike } from 'react-icons/ai';
import { IoIosPeople } from 'react-icons/io';
import aboutFrom from "../../assets/about.jpg";
import TopCourses from './TopCourses';
import { accordions, categories, courses } from '../../Data';
import Course from './Courses/Course';
import Categories from './Courses/Categories';
import Accordion from './Accordion';
import MyNavbar from '../Navbar/MyNavbar';

const Service = () => {
      const container = {
        hidden: {
          opacity: 0,
          scale: 0,
        },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2,
          },
        },
      };
    useEffect(() => {
      AOS.init({ duration: "1000" });
    });
    const blog = [
      {
        id: 1,
        describe: "Discover the Sweet Journey of Mango Cargo",
        image: [blog_1],
      },
      {
        id: 2,
        describe: "Exploring the Exotic Origins of Mango Cargo",
        image: [blog_2],
      },
      {
        id: 3,
        describe: "Unveiling the Secrets Behind Mango Cargo's Success",
        image: [blog_3],
      },
      
    ];
     const service = [
       {
         id: 1,
         title: "UI/UX Designer",
         describe:
           "Craft user interfaces that are intuitive, beautiful, and user-friendly.",
         icon: <FaPalette className="text-4xl mx-auto text-violet-700" />, // Palette icon for UI/UX design
       },
       {
         id: 2,
         title: "Front-End Engineer",
         describe:
           "Bring websites and applications to life using JavaScript, HTML, and CSS.",
         icon: <FaCode className="text-4xl mx-auto text-blue-700" />, // Code icon for development
       },
       {
         id: 3,
         title: "Full-Stack Developer (Frontend Focus)",
         describe:
           "Build both the front-end and back-end of applications, with an emphasis on user interfaces.",
         icon: <FaLaptopCode className="text-4xl mx-auto text-teal-700" />, // Laptop with code for full-stack with frontend focus
       },
     ];
  return (
    <div className="">
      <MyNavbar />
      <div className="section md:flex items-center justify-center space-y-8 md:space-y-0 md:space-x-8 p-8">
        <div className="max-w-md">
          <div className="font-bold text-sm text-amber-700 mb-4 uppercase tracking-wider">
            Your career choice partner
          </div>
          <div className="sm:text-[2.5rem] text-[1.825rem] font-bold leading-tight text-gray-900">
            This is <br /> the new way <br /> to learn and choose your career
          </div>
          <p className="text-sm leading-7 text-gray-600 mt-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero
            officia sit vitae quo, eum similique?
          </p>
          <div className="mt-6 flex space-x-4">
            <button className="px-6 py-3 font-bold text-white rounded-lg text-sm bg-gradient-to-r from-red-500 via-amber-700 to-yellow-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400">
              Get Started
            </button>
            <button className="px-6 py-3 font-bold border border-solid border-gray-300 rounded-lg text-sm text-gray-900 hover:bg-gray-100">
              Discover
            </button>
          </div>
        </div>
        <div className="md:w-[50%]">
          <img
            src={hero}
            alt="Hero image"
            className="w-full rounded-lg shadow-lg"
          />
        </div>
      </div>
      <div>
        <div className="bg-gradient-to-r from-gray-100 via-white to-gray-100 py-12">
          <h1
            className="text-center text-5xl font-bold text-gray-800 mb-12 animate-fadeIn"
            data-aos="fade-up"
          >
            About Us
          </h1>
          <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto">
            <div className="md:w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
              <div className="w-full md:w-1/2" data-aos="fade-right">
                <img
                  src={aboutFrom}
                  alt="About Us"
                  className="w-full h-auto rounded-lg shadow-lg animate-zoomIn"
                />
              </div>
              <div
                className="md:w-3/5 mx-auto bg-white p-8 rounded-lg shadow-lg animate-fadeInRight"
                data-aos="fade-left"
              >
                <p className="text-3xl text-amber-600 font-semibold mb-4">
                  Why Choose Our Service
                </p>
                <h2 className="text-4xl text-stone-700 font-semibold mb-6">
                  We Are Professional Logistics & Cargo Agency
                </h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. A
                  explicabo ad sint qui aut, totam obcaecati beatae quidem rem
                  illum dolorum cum, at facere, doloremque ipsum iure dolore
                  minima! Reiciendis optio debitis saepe odio ex iste nemo
                  aspernatur magnam amet. Perspiciatis quos nostrum, recusandae
                  quo tenetur sapiente reiciendis sequi, laboriosam ullam illum
                  provident error iste consequatur praesentium officia, cumque
                  aliquam? Sequi iure placeat non delectus nulla excepturi et
                  alias modi eaque aspernatur quis, quaerat sed voluptas vero
                  dignissimos deserunt! Voluptas harum reiciendis quam nam
                  officiis veritatis, explicabo molestias dolores deserunt
                  distinctio reprehenderit enim quisquam facere. Distinctio
                  alias dolore ducimus reprehenderit.
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto bg-gray-50 py-16">
            <div className="flex flex-col md:flex-row justify-between items-center gap-8">
              <div className="md:w-1/2">
                <h2 className="text-4xl text-gray-600 font-semibold mb-4 w-2/3">
                  Helping to reach the to Customer{" "}
                  <span className="text-amber-500">
                    For customer Satisfaction
                  </span>
                </h2>
                <p className="text-lg text-gray-600">
                  We reached here with hard work and dedication.
                </p>
              </div>
              <div className="md:3/4 mx-auto flex sm:flex-row flex-col sm:items-center justify-around gap-12 animate-fadeInUp">
                <div className="space-y-8">
                  <div className="flex items-center gap-4">
                    <IoIosPeople className="text-6xl text-amber-700 animate-bounce" />
                    <div>
                      <h4 className="text-2xl font-bold">2000</h4>
                      <p className="text-lg text-gray-600">Members</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <FaTruck className="text-6xl text-amber-700 animate-bounce" />
                    <div>
                      <h4 className="text-2xl font-bold">200</h4>
                      <p className="text-lg text-gray-600">Courses</p>
                    </div>
                  </div>
                </div>
                <div className="space-y-8">
                  <div className="flex items-center gap-4">
                    <AiOutlineLike className="text-6xl text-amber-700 animate-bounce" />
                    <div>
                      <h4 className="text-2xl font-bold">1000</h4>
                      <p className="text-lg text-gray-600">Likes</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <FaMoneyCheck className="text-6xl text-amber-700 animate-bounce" />
                    <div>
                      <h4 className="text-2xl font-bold">2000</h4>
                      <p className="text-lg text-gray-600">Earnings</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <OurMission />
          </div>
        </div>
      </div>
      <div className="mt-20 md:w-1/2 mx-auto text-center">
        <h2
          className="text-4xl text-stone-800 font-semibold mb-3"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          Services We Provide
        </h2>
        <p className="text-gray-600" data-aos="fade-up" data-aos-delay="200">
          Explore the variety of services we offer to our clients
        </p>
      </div>
      <div
        className="mt-14 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12 md:w-11/12 mx-auto"
        data-aos="fade-up"
      >
        {service.map((service) => (
          <div key={service.id}>
            <div className="px-4 py-8 text-center md:w-[300px] mx-auto md:h-80 rounded-md shadow-lg transform transition-transform hover:-translate-y-2 duration-300 border border-gray-200 hover:bg-teal-200 hover:text-white">
              <div className="bg-white p-2 rounded-full w-16 h-16 mx-auto mb-4 shadow-inner">
                {service.icon}
              </div>
              <h4 className="text-2xl font-bold text-stone-800 mb-2 hover:text-white">
                {service.title}
              </h4>
              <p className="text-gray-600 hover:text-white">
                {service.describe}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="section" id="courses">
        <div className="text-center mb-12">
          <h2 className="sm:text-3xl text-2xl font-bold mb-5">
            Our Top <span className="text-amber-600">Categories</span>
          </h2>
          <p className="text-sm text-gray leading-7 max-w-[700px] mx-auto">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore
            tempora illo laborum ex cupiditate tenetur doloribus non velit atque
            amet repudiandae ipsa modi numquam quas odit optio, totam voluptate
            sit! Lorem ipsum dolor sit amet.
          </p>
        </div>

        <motion.div
          variants={container}
          initial="hidden"
          whileInView="visible"
          className="grid md:grid-cols-4 sm:grid-cols-1 gap-8 mb-12"
        >
          {categories.map((category) => (
            <Categories key={category.id} {...category} />
          ))}
        </motion.div>

        <div className="text-xl font-bold mb-5">All of Our Courses</div>

        <div className="relative overflow-x-hidden w-full mb-12">
          <div className="flex gap-2 md:w-full sm:w-[170%] xs:w-[340%] w-[480%]">
            {courses.map((course) => (
              <Course key={course.id} {...course} />
            ))}
          </div>
        </div>

        
      </div>
      <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto mb-12">
        <div className="text-center md:w-1/2 mx-auto">
          <h2
            className="text-4xl text-gray-700 font-semibold mb-4"
            data-aos="fade-up"
            data-aos-offset="200"
          >
            Our Top Courses
          </h2>
          <p
            className="text-sm text-neutralGrey mb-8 md:w-3/4 mx-auto"
            data-aos="fade-up"
            data-aos-offset="300"
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
            Perspiciatis temporibus expedita consectetur corrupti doloribus eum?
            Officia, omnis minus adipisci eos dolorem explicabo error illo
            eveniet placeat odit dolorum facilis quod.
          </p>
        </div>
        <div
          className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 items-center justify-between"
          data-aos="fade-up"
          data-aos-offset="400"
        >
          {blog.map((blog) => (
            <div
              key={blog.id}
              className="mx-auto relative mb-12 cursor-pointer"
            >
              <img
                className="w-96 hover:scale-95 transition-all duration-300"
                src={blog.image}
                alt=""
              />
              <div className="text-center px-4 py-8 bg-white shadow-lg rounded-md md:w-3/4 mx-auto absolute left-0 right-0 -bottom-12">
                <h3 className="mb-3 text-stone-600 font-semibold">
                  {blog.describe}
                </h3>
                <div className="flex items-center justify-center gap-8">
                  <a
                    href="/"
                    className="font-bold text-lime-500  hover:text-amber-400 text-center"
                  >
                    Read More
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="text-center my-16">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">
          Frequently <span className="text-amber-700">Asked Questions</span>
        </h2>
        <div className="max-w-3xl mx-auto mt-8">
          {accordions.map((accordion) => (
            <Accordion key={accordion.id} {...accordion} />
          ))}
        </div>
      </div>
      <div className="section">
        <div className="text-center max-w-[600px] mx-auto">
          <div className="sm:text-3xl text-2xl font-bold mb-5">
            Subscribe Newsletter
          </div>
          <p className="text-sm leading-7 text-gray">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum autem
            minus doloribus voluptatem illo velit quia eum aperiam! Repudiandae,
            tempore. Lorem ipsum dolor sit amet.
          </p>
          <motion.form
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            className="mt-5"
          >
            <input
              type="text"
              placeholder="Enter your email address"
              className="sm:p-3 p-2 outline-none text-sm shadow-md sm:w-72 w-60"
            />
            <button className="text-sm text-white bg-amber-700 sm:p-3 p-2 shadow-md font-bold">
              Subscribe
            </button>
          </motion.form>
        </div>
      </div>
      <MyFooter />
    </div>
  );
}

export default Service
