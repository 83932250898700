import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const QuestionType = () => {
  useEffect(() => {
    AOS.init({ duration: "1000" });
  });

  const navigation = useNavigate();

  return (
    <div className="Admin">
      <Navbar />
      <div className="flex flex-col min-h-screen justify-center items-center bg-gradient-to-r from-purple-100 to-blue-100">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-bold text-gray-800">
            Choose Question Type
          </h1>
          <p className="text-lg text-gray-600 mt-2">
            Select the type of question you want to create and add chapter
          </p>
        </div>
        <div className="flex flex-wrap justify-center gap-8">
          {/* <div
            className="transition ease-in-out delay-150 max-w-sm w-full rounded-lg shadow-lg bg-blue-500 m-3 hover:bg-blue-600 hover:scale-105 duration-300 cursor-pointer flex items-center justify-center transform hover:shadow-2xl"
            onClick={() => navigation("/MCQ")}
          >
            <div className="text-center p-6">
              <h5 className="text-3xl font-bold text-white">MCQ</h5>
            </div>
          </div> */}
        
          <div
            className="transition ease-in-out delay-150 max-w-sm w-full rounded-lg shadow-lg bg-blue-500 m-3 hover:bg-blue-600 hover:scale-105 duration-300 cursor-pointer flex items-center justify-center transform hover:shadow-2xl"
            onClick={() => navigation("/Answer_Bloom_Question")}
          >
            <div className="text-center p-6">
              <h5 className="text-3xl font-bold text-white">BLOOM</h5>
            </div>
          </div>
          {/* <div
            className="transition ease-in-out delay-150 max-w-sm w-full rounded-lg shadow-lg bg-blue-500 m-3 hover:bg-blue-600 hover:scale-105 duration-300 cursor-pointer flex items-center justify-center transform hover:shadow-2xl"
            onClick={() => navigation("/Add_Chapter")}
          >
            <div className="text-center p-6">
              <h5 className="text-3xl font-bold text-white">Add Chapter</h5>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default QuestionType;
