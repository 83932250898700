import React from 'react'
import Navbar from '../components/Navbar';

const AddBloomChapter = () => {
  return (
    <div className="Admin h-screen">
      <Navbar />
      <div className="flex flex-col p-5">
        <form class="w-full mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div class="mb-5">
            <h3 className="text-2xl font-semibold text-center text-blue-700 mb-20">
              ADD CHAPTER NAME
            </h3>
          </div>
          <div class="mb-4">
            <label
              for="chapterName"
              class="block mb-2 text-sm font-medium text-gray-700"
            >
              Chapter Name:
            </label>
            <input
              type="text"
              id="chapterName"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter chapter name"
              required
            />
          </div>
          <button
            type="submit"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Add
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddBloomChapter
