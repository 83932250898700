import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import JobSector from "../assets/depositphotos_62060317-stock-illustration-person-with-megaphone-and-word.jpg";
import JobPosition from "../assets/publicación-de-trabajos-con-lupa-sobre-fondo-blanco-215073215.png";
import setComtency from "../assets/59387929-hand-writing-core-competencies-mind-map-flowchart-business-concept-for-presentations-and-reports.jpg";
import MapComtency from "../assets/istockphoto-1370084410-612x612.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const HRAdmin = () => {
  const navigation = useNavigate();
  const role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");

  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     navigation("/login");
  //   }
  // }, []);
  useEffect(() => {
    AOS.init({ duration: "1000" });
  });
  // useEffect(() => {
  //   if (!localStorage.getItem("token")) {
  //     navigation("/login");
  //   }
  // }, []);
  return (
    <div className="Admin">
      <Navbar />
      <div className="flex flex-col min-h-screen justify-center items-center">
        {/*       {role == 'Question Creator' && 
                    <div  className='grid grid-cols-2 gap-1 justify-items-center '>
                    <div onClick={() => navigation('/question')} className='transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200'>
                        <h2 className='text-2xl text-center px-6 py-6'>Question Bank</h2>
                    </div>
    </div>} */}

        <div className="grid grid-cols-1 md:grid-cols-4 gap-3 ">
          {/* <div onClick={() => navigation('/add-sector')} className='transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200'>
                                <h2 className='text-2xl text-center px-6 py-6'>Job Sectors</h2>
</div> */}
          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={JobSector}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Job Sectors
              </h5>

              <p class="mb-3 font-normal">
                This option effectively captures both the creative aspect and
                the broader impact the future of learning.
              </p>
              <button
                onClick={() => navigation("/add-sector")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Job Sector
              </button>
            </div>
          </div>

          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={JobPosition}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Job Posting
              </h5>

              <p class="mb-3 font-normal">
                In this option effectively captures both the creative aspects of
                the understanding skills and jobs
              </p>
              <button
                onClick={() => navigation("/add-job")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Job Posting
              </button>
            </div>
          </div>

          {/*        <div
                  onClick={() => navigation("/add-job")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Job Positions
                  </h2>
</div> */}

          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={setComtency}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Set Competency
              </h5>

              <p class="mb-3 font-normal">
                In this option effectively captures both the creative aspects of
                the understanding skills and jobs
              </p>
              <button
                onClick={() => navigation("/add-skill")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Set Competency
              </button>
            </div>
          </div>

          {/*        <div
                  onClick={() => navigation("/add-skill")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Set Competency
                  </h2>
</div> */}

          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={MapComtency}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Mapping Competency
              </h5>

              <p class="mb-3 font-normal">
                In this option effectively captures both the creative aspects of
                the understanding skills and jobs
              </p>
              <button
                onClick={() => navigation("/map-competency")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Mapping Competency
              </button>
            </div>
          </div>
          {/*        <div
                  onClick={() => navigation("/map-competency")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Mapping Competency
                  </h2>
</div> */}
        </div>
      </div>
    </div>
  );
};

export default HRAdmin;
