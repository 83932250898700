import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import { FaBookOpen } from 'react-icons/fa';
import axios from 'axios';

const StudentExam = () => {
  const [chapters, setChapters] = useState([]);
  useEffect(() => {
    AOS.init({ duration: "1000" });
  }, []);

  const navigation = useNavigate();

  useEffect(() => {
    fetchChapters();
  }, []);

  const fetchChapters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_API}/chapter/`
      );
      setChapters(response.data);
      console.log(response.data);
    } catch (error) {
      console.log("Error Fetching Chapter", error);
    }
  };
  return (
    <div className="Admin min-h-screen">
      <Navbar />

      <div className="flex flex-col min-h-screen p-5 bg-gray-100">
        <h1 className="text-4xl font-bold text-center mb-10 text-gray-800 text-blue-700">
          Select Chapter for BLOOM Questions
        </h1>
        <div className="flex flex-wrap justify-center gap-6 mt-6">
          {chapters.map((chapter) => (
            <div
              key={chapter.id}
              className="transition ease-in-out delay-150 max-w-sm w-full rounded-lg shadow-lg bg-gradient-to-r from-green-400 to-green-600 m-3 hover:from-green-500 hover:to-green-700 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center"
              data-aos="fade-up"
              onClick={() => {
                localStorage.setItem("chapter_name", chapter.chapter_name)
                localStorage.setItem('chapter_id', chapter.id)
                navigation("/Student_Bloom_Answer");
              }}
            >
              <div className="text-center p-6 flex gap-6">
                <FaBookOpen className="text-white text-4xl mb-4" />
                <h5 className="text-2xl font-bold tracking-tight text-white">
                  {chapter.chapter_name} (BLOOM)
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/*<div className=" flex justify-center align-middle items-center mt-6">
        <div className="flex flex-col min-h-screen">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-10 py-10"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Student_Bloom_Answer")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  BLOOM QUIZ 1
                </h5>

                <p className="font-semibold">Chapter 1 (BLOOM)</p>
                <p className="font-semibold">BLOOMS</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-10 py-10"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Student_Bloom_Answer")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  BLOOM QUIZ 2
                </h5>

                <p className="font-semibold">Chapter 2 (BLOOM)</p>
                <p className="font-semibold">BLOOMS</p>
              </div>
            </div> */}
      {/*    <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-10 py-10"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Student_MCQ_Answer")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  MCQ QUIZ 1
                </h5>

                <p className="font-semibold">Chapter 1 (MCQ)</p>
                <p className="font-semibold">MCQ</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-10 py-10"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Student_MCQ_Answer")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  MCQ QUIZ 2
                </h5>

                <p className="font-semibold">Chapter 2 (MCQ)</p>
                <p className="font-semibold">MCQ</p>
              </div>
            </div> 
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default StudentExam
