import React, { useEffect } from 'react'
import Navbar from '../components/Navbar';
import Subject from '../assets/images.jpg'
import { useNavigate } from 'react-router-dom';
import Student from "../assets/studentenrollment.png";
import Teacher from "../assets/118097650-the-students-give-their-teachers-homework (2).jpg";
import Admin from "../assets/admin-office-binder-wooden-desk-table-colored-pencil-pencils-pen-notebook-paper-79046621.png";
import Batch from "../assets/01-5001-batch-mini-number-stamp-mockup_1024x1024.jpg";
import Course from '../assets/choose-the-top-lms-for-elearning-course-creation.jpg';
import AOS from "aos";
import "aos/dist/aos.css";

const SuperAdmin = () => {
  useEffect(() => {
    AOS.init({ duration: "1000" });
  });
  const navigation = useNavigate();
  return (
    <div className="Admin">
      <Navbar />
      <div className=" flex justify-center align-middle items-center">
        <div className="flex flex-col min-h-screen justify-center items-center">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div
              className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
                src={Student}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  Student Enroll
                </h5>

                <p class="mb-3 font-normal">
                  In here We can enroll student and check their details
                </p>
                <button
                  onClick={() => navigation("/Student_Enroll_Page")}
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Enroll Student
                </button>
              </div>
            </div>

            {/*    <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
                src={Teacher}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  Teacher Enroll
                </h5>

                <p class="mb-3 font-normal">
                  In this we can assign teacher and enter their details
                </p>
                <button
                  onClick={() => navigation("/Teacher_Assign_Page")}
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Enroll Teacher
                </button>
              </div>
  </div> */}

            {/*        <div
                  onClick={() => navigation("/add-job")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Job Positions
                  </h2>
</div> */}

            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer filter max-h-72 w-full"
                src={Admin}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  HR Admin Create
                </h5>

                <p class="mb-3 font-normal">
                  In this option we can create and Admin roles
                </p>
                <button
                  onClick={() => navigation("/Create_Admin_Page")}
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Create Admin
                </button>
              </div>
            </div>

            {/*        <div
                  onClick={() => navigation("/add-skill")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Set Competency
                  </h2>
</div> */}
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200 justify-center"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
                src={Teacher}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  Assign Teacher
                </h5>

                <p class="mb-3 font-normal">
                  In this option you can assign teacher
                </p>
                <button
                  onClick={() =>
                    // window.open("https://www.promiseedutech.com/admin/course")
                    navigation("/Assign_Teacher")
                  }
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Assign Teacher
                </button>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
              data-aos="fade-up"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
                src={Course}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  Course Creation
                </h5>

                <p class="mb-3 font-normal">
                  In this option we can create course
                </p>
                <button
                  onClick={() =>
                    // window.open("https://www.promiseedutech.com/admin/course")
                    navigation("/Course_Create")
                  }
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Create Course
                </button>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
              data-aos="fade-up"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover h-72"
                src={Batch}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  Batch Creation
                </h5>

                <p class="mb-3 font-normal">
                  In this option we can create batch
                </p>
                <button
                  onClick={() => navigation("/Batch_Create")}
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Create Batch
                </button>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
              data-aos="fade-up"
            >
              <img
                className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover h-72"
                src={Subject}
                alt=""
              />

              <div className="p-5">
                <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                  Subject Creation
                </h5>

                <p class="mb-3 font-normal">
                  In this option we can create subject
                </p>
                <button
                  onClick={() => navigation("/Subject_Creation")}
                  class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  Create Subject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperAdmin
