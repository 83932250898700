import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import axios from "axios";

const SuperAdminAddAdmin = () => {
  const notify = () => toast("Admin Added Successfully");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [organize, setOrganize] = useState("");

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.mobile_no,
    },
    {
      name: "Organization Name",
      selector: (row) => row.organization,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="">
          <button className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
            Delete
          </button>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([]);

  const getData = () => {
    axios.get(`${process.env.REACT_APP_NODE_API}/hr_admin/`).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const submit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_NODE_API}/user/register`, {
        name,
        email,
        mobile: phone,
        password,
        role: "HR admin",
      })
      .then((res) => {
        axios
          .post(`${process.env.REACT_APP_NODE_API}/hr_admin/add`, {
            user_id: res.data.user_id,
            organization: organize,
          })
          .then((res) => {
            toast("Added");
            getData();
          });
      });
  };

  return (
    <div className="Admin">
      <Navbar />
      <ToastContainer />
      <div className="grid place-items-center bg-gray-100 min-h-screen py-10 px-4">
        <div className="p-5 text-center">
          <h1 className="font-poppins text-4xl font-semibold text-blue-700">
            Assign Admin
          </h1>
        </div>
        <form
          className="md:w-1/3 bg-white shadow-lg rounded-lg p-6"
          onSubmit={submit}
        >
          <div className="grid grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Name
              </label>
              <input
                className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Name"
              />
            </div>
            <div className="flex flex-col">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Email
              </label>
              <input
                className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Email"
              />
            </div>
            <div className="flex flex-col">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Phone
              </label>
              <input
                className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Enter Phone Number"
              />
            </div>
            <div className="flex flex-col">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Password
              </label>
              <input
                className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Password"
              />
            </div>
            <div className="flex flex-col col-span-2">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Organization Name
              </label>
              <input
                className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
                value={organize}
                onChange={(e) => setOrganize(e.target.value)}
                placeholder="Enter Organization"
              />
            </div>
          </div>
          <div className="mt-6 text-center">
            <button
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-md px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        <div className="container mt-10">
          <DataTable
            className="z-0 mx-auto bg-white rounded-lg shadow-md"
            columns={columns}
            data={data}
            pagination
          />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddAdmin;
