import React, { useState } from "react";
import Navbar from "../components/Navbar";
import DataTable from "react-data-table-component";
import avatar from "../assets/87524034_729307304270114_4445245032001175552_n.jpg";

const StudentEvaluateJob = () => {
  const [matchRate, setMatchRate] = useState(81);
  const [time, setTime] = useState(30.01);

  const [score, setScore] = useState({
    Creativity: 13.5,
    Evaluation: 13.5,
    Analysis: 13.5,
    Apply: 13.5,
    Understand: 13.5,
    Remember: 13.5,
  });

  const columns = [
    {
      name: "PROFESSION",
      selector: (row) => row.profession,
      sortable: true,
    },
    {
      name: "CREATIVITY",
      selector: (row) => row.creativity,
      sortable: true,
    },
    {
      name: "EVALUATION",
      selector: (row) => row.evaluation,
      sortable: true,
    },
    {
      name: "ANALYSIS",
      selector: (row) => row.analysis,
      sortable: true,
    },
    {
      name: "APPLY",
      selector: (row) => row.apply,
      sortable: true,
    },
    {
      name: "UNDERSTAND",
      selector: (row) => row.understand,
      sortable: true,
    },
    {
      name: "REMEMBER",
      selector: (row) => row.remember,
      sortable: true,
    },
    {
      name: "AVERAGE MARKS",
      selector: (row) => row.marks,
      sortable: true,
    },
    {
      name: "POSITION",
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: "Match Percentage",
      selector: (row) => row.match,
      sortable: true,
    },
  ];

  const data = [
    {
      profession: "Medical Science",
      creativity: 4,
      evaluation: 5,
      analysis: 2,
      apply: 3,
      understand: 4,
      remember: 2,
      marks: 20,
      position: "Research scientist ",
      match: "20%",
    },
  ];

  return (
    <div className="Admin bg-gray-100 min-h-screen">
      <Navbar />
      <div className="flex flex-wrap p-4">
        <div className="w-full md:w-1/3 p-2">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <div className="relative w-40 h-40 rounded-full bg-gradient-to-r from-green-400 to-green-600 mx-auto">
              <div className="absolute inset-1 bg-white rounded-full flex items-center justify-center">
                <span className="text-3xl font-bold text-green-600">
                  {matchRate}%
                </span>
              </div>
            </div>

            <h2 className="text-2xl font-bold text-center mt-4">Best Match</h2>

            <div className="w-full mt-4">
              <p className="bg-green-100 mx-2 text-green-800 text-sm px-4.5 py-0.5 rounded dark:bg-gray-700 border border-green-400 text-center">
                Software Development
              </p>
              <p className="text-sm text-gray-500 mt-2 text-center">
                <span className="text-green-600">81%</span> percent rate is
                matched
              </p>
            </div>

            <div className="w-full mt-4">
              {Object.entries(score).map(([key, count]) => (
                <div
                  key={key}
                  className="flex items-center gap-1 text-xs bg-gray-50 p-2 rounded-lg shadow-sm mt-2"
                >
                  <span className="font-bold capitalize">{key}</span>
                  <span className="ml-auto text-xs">{`${count} ${
                    count > 1 ? "s" : ""
                  } score`}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/3 p-2">
          <div className="bg-white p-6 rounded-lg shadow-lg h-full">
            <div className="flex flex-wrap items-center">
              <img
                className="w-40 h-40 rounded-full"
                src={avatar}
                alt="Profile Avatar"
              />
            </div>
            <div className="mt-4">
              <h2 className="text-xl font-semibold text-gray-800 mb-2">
                Student Info
              </h2>
              <ul className="list-none mb-4">
                <li className="text-gray-600 flex items-center mb-2">
                  <span className="font-semibold mr-2">Name:</span> Atif Aziz
                </li>
                <li className="text-gray-600 flex items-center mb-2">
                  <span className="font-semibold mr-2">Gender:</span> Male
                </li>
                <li className="text-gray-600 flex items-center mb-2">
                  <span className="font-semibold mr-2">Occupation:</span>{" "}
                  Student
                </li>
                <li className="text-gray-600 flex items-center mb-2">
                  <span className="font-semibold mr-2">Class:</span> Under
                  Graduate
                </li>
              </ul>
              <div className="border-t border-gray-200 pt-4">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">
                  Contact
                </h3>
                <ul className="list-none text-sm text-gray-600">
                  <li className="flex items-center mb-2">
                    <i className="fas fa-envelope mr-2"></i> Email:
                    atif@gmail.com
                  </li>
                  <li className="flex items-center">
                    <i className="fas fa-phone mr-2"></i> Phone Number:
                    0123463401293
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full p-2 mt-6">
          <div className="bg-white rounded shadow-lg p-6">
            <h2 className="text-3xl font-bold text-center text-blue-600 mb-6">
              Career Path
            </h2>
            <DataTable
              columns={columns}
              data={data}
              pagination
              highlightOnHover
              pointerOnHover
              className="border border-gray-300 rounded"
              customStyles={{
                rows: {
                  style: {
                    minHeight: "72px",
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    backgroundColor: "#f7fafc",
                  },
                },
                cells: {
                  style: {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentEvaluateJob;
