import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const StudentEnroll = () => {


  const getData = () => {
    axios.get(`${process.env.REACT_APP_NODE_API}/student/?approved=0`)
      .then(res => {
        setData(res.data)
      })
  }



  const enroll = (e, id) => {
    e.preventDefault()
    axios.put(`${process.env.REACT_APP_NODE_API}/student/approve?id=${id}`)
      .then(res => {
        getData()
        toast.success("Student Enrolled Successfully");
      })

  }


  const columns = [
    { name: "Student Name", selector: (row) => row.name, sortable: true },
    { name: "Institute Name", selector: (row) => row.institute_name, sortable: true },
    { name: "Student Email", selector: (row) => row.email, sortable: true },
    { name: "Course ID", selector: (row) => row.course_id, sortable: true },
    { name: "Phone Number", selector: (row) => row.mobile_no, sortable: true },

    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-2">
          <button
            onClick={e => enroll(e, row.id)}
            className="text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-md px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
          >
            ENROLL
          </button>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <ToastContainer />
      <div className="grid place-items-center bg-gray-100 py-10 px-4">
        <div className="p-5 text-center">
          <h1 className="font-poppins text-4xl font-semibold text-blue-700">
            Enroll Student
          </h1>
        </div>

        <div className="container mt-10">
          <DataTable
            className="z-0 mx-auto bg-white rounded-lg shadow-md"
            columns={columns}
            data={data}
            pagination
          />
        </div>
      </div>
    </div>
  );
};

export default StudentEnroll;
