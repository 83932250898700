import React from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'

const Jobs = () => {
    const navigation = useNavigate()
    return (
      <div className=''>
          <Navbar />
          <div className=''>
              <div  className='grid grid-cols-2 gap-1 justify-items-center '>
                  <div onClick={() => navigation('/job/add-sector')} className='transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200'>
                      <h2 className='text-2xl text-center px-6 py-6'>Create Sector</h2>
                  </div>
                  <div onClick={() => navigation('/job/add-job')} className='transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200'>
                      <h2 className='text-2xl text-center px-6 py-6'>Create Job Post</h2>
                  </div>
                  <div onClick={() => navigation('/job/add-skill')} className='transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200'>
                      <h2 className='text-2xl text-center px-6 py-6'>Create Job Skill</h2>
                  </div>
              </div>
          </div>
          
      </div>
    )
}

export default Jobs