import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import missionPage from "../../assets/career-path.jpg";

const OurMission = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-gradient-to-r from-gray-100 via-white to-gray-100 py-12 mb-24">
      <h1
        className="text-center text-5xl font-bold text-gray-800 mb-12 animate-fadeIn"
        data-aos="fade-up"
      >
        Our Mission
      </h1>
      <div className="px-4 lg:px-14 py-14 max-w-screen-2xl mx-auto">
        <div className="md:w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
          <div
            className="md:w-3/5 mx-auto bg-white p-8 rounded-lg shadow-lg"
            data-aos="fade-right"
          >
            <p className="text-3xl text-amber-600 font-semibold mb-4">
              Our Mission provide
            </p>
            <p className="text-lg text-gray-700 leading-relaxed">
              Our mission is to provide top-tier logistics and cargo services
              that ensure the seamless transportation and delivery of goods. We
              are dedicated to exceeding our clients' expectations by offering
              reliable, efficient, and innovative solutions. Our team of
              professionals works tirelessly to maintain the highest standards
              of service, safety, and sustainability in all our operations. With
              a customer-centric approach, we aim to build long-lasting
              relationships and contribute positively to the global supply
              chain.
            </p>
          </div>
          <div className="w-full md:w-1/2 h-full" data-aos="fade-left">
            <img
              src={missionPage}
              alt="Our Mission"
              className="w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
