import React, { useEffect } from "react";
import blog_1 from "../../assets/courses1.jpg";
import blog_2 from "../../assets/courses4.jpg";
import blog_3 from "../../assets/courses7.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const TopCourses = () => {
  useEffect(() => {
    AOS.init({ duration: "1000" });
  });
  const blog = [
    {
      id: 1,
      describe: "Discover the Sweet Journey of Mango Cargo",
      image: [blog_1],
    },
    {
      id: 2,
      describe: "Exploring the Exotic Origins of Mango Cargo",
      image: [blog_2],
    },
    {
      id: 3,
      describe: "Unveiling the Secrets Behind Mango Cargo's Success",
      image: [blog_3],
    },
  ];
  return (
    <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto mb-12">
      <div className="text-center md:w-1/2 mx-auto">
        <h2
          className="text-4xl text-gray-700 font-semibold mb-4"
          data-aos="fade-up"
          data-aos-offset="200"
        >
          Our Top Courses
        </h2>
        <p
          className="text-sm text-neutralGrey mb-8 md:w-3/4 mx-auto"
          data-aos="fade-up"
          data-aos-offset="300"
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis
          temporibus expedita consectetur corrupti doloribus eum? Officia, omnis
          minus adipisci eos dolorem explicabo error illo eveniet placeat odit
          dolorum facilis quod.
        </p>
      </div>
      <div
        className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 items-center justify-between"
        data-aos="fade-up"
        data-aos-offset="400"
      >
        {blog.map((blog) => (
          <div key={blog.id} className="mx-auto relative mb-12 cursor-pointer">
            <img
              className="w-96 hover:scale-95 transition-all duration-300"
              src={blog.image}
              alt=""
            />
            <div className="text-center px-4 py-8 bg-white shadow-lg rounded-md md:w-3/4 mx-auto absolute left-0 right-0 -bottom-12">
              <h3 className="mb-3 text-stone-600 font-semibold">
                {blog.describe}
              </h3>
              <div className="flex items-center justify-center gap-8">
                <a
                  href="/"
                  className="font-bold text-lime-500  hover:text-amber-400 text-center"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopCourses;
