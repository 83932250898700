import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';

const McqTotalGivenExam = () => {
     const navigate = useNavigate();
     const [batch, setBatch] = useState("");
     const [classes, setClasses] = useState("");
     const [institute, setInstitute] = useState("");
     const [institute_type, setInstitute_Type] = useState("");

     useEffect(() => {
       console.log("");
     }, []);

     const columns = [
       { name: "STUDENT NAME", selector: (row) => row.name, sortable: true },
       { name: "COURSE", selector: (row) => row.course, sortable: true },
       { name: "BATCH", selector: (row) => row.batches, sortable: true },
       { name: "CLASS", selector: (row) => row.student_class, sortable: true },
       { name: "EXAM", selector: (row) => row.exam, sortable: true },
       {
         name: "QUESTION CATEGORY",
         selector: (row) => row.student_class,
         sortable: true,
       },
       {
         name: "INSTITUTE TYPE",
         selector: (row) => row.institute_type,
         sortable: true,
       },
       {
         name: "ORGANIZATION NAME",
         selector: (row) => row.organize_name,
         sortable: true,
       },
       {
         name: "Actions",
         cell: (row) => (
           <button
             onClick={() => navigate("/Student_Add_Marks_Bloom")}
             className="text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-md px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
           >
             MCQ Response
           </button>
         ),
       },
     ];

     const data = [
       {
         id: 1,
         name: "test",
         course: "Web Development",
         batches: "Batch 1",
         student_class: "Class 1",
         institute_type: "School",
         organize_name: "National Ideal School",
         exam: "MCQ Quiz 1",
       },
     ];
  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <div className="container mx-auto p-5">
        <div className="bg-white rounded shadow-lg p-6 mt-6">
          <h2 className="text-3xl font-bold text-center text-blue-600 mb-6">
            Total Student Attend On MCQ Exam
          </h2>
          <div className="mb-4 flex justify-center items-center">
            <div className="relative w-full"></div>
          </div>
          <DataTable
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            pointerOnHover
            className="border border-gray-300 rounded"
            customStyles={{
              rows: {
                style: {
                  minHeight: "72px",
                },
              },
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#f7fafc",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default McqTotalGivenExam
