import React from "react";
import { accordions } from "../../Data";
import Accordion from "./Accordion";
import teacher1 from "../../assets/teacehr2-Photoroom.png";
import teacher2 from "../../assets/Teacher-Photoroom.png";

const TeacherPage = () => {
  return (
    <div className="section bg-gray-100 py-12" id="teacher">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="text-center lg:text-left">
            <h2 className="text-4xl font-bold text-gray-800 mb-4">
              Become <span className="text-amber-700">An Instructor</span>{" "}
              <br /> of Our Platform
            </h2>
            <p className="text-lg text-gray-600 mb-6">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora
              perferendis debitis dolorum facilis culpa, quidem voluptate
              suscipit deserunt.
            </p>
            <button className="py-3 px-6 bg-amber-700 text-white rounded-lg text-lg font-semibold shadow-lg hover:bg-amber-600 transition duration-300">
              Start Teaching
            </button>
          </div>
          <div className="grid place-items-center">
            <img src={teacher1} alt="Teacher 1" className="w-full" />
          </div>
        </div>
        <div className="mt-12">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="grid place-items-center">
              <img src={teacher2} alt="Teacher 2" className="w-full" />
            </div>
            <div className="text-center lg:text-left">
              <h2 className="text-4xl font-bold text-gray-800 mb-4">
                Become <span className="text-amber-700">An Instructor</span>{" "}
                <br /> of Our Platform
              </h2>
              <p className="text-lg text-gray-600 mb-6">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora
                perferendis debitis dolorum facilis culpa, quidem voluptate
                suscipit deserunt.
              </p>
              <button className="py-3 px-6 bg-amber-700 text-white rounded-lg text-lg font-semibold shadow-lg hover:bg-amber-600 transition duration-300">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center my-16">
        <h2 className="text-4xl font-bold text-gray-800 mb-4">
          Frequently <span className="text-amber-700">Asked Questions</span>
        </h2>
        <div className="max-w-3xl mx-auto mt-8">
          {accordions.map((accordion) => (
            <Accordion key={accordion.id} {...accordion} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeacherPage;
