import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import DataTable from "react-data-table-component";
import { FaSearch } from "react-icons/fa";
import "tailwindcss/tailwind.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const StudentResults = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate()
  const [exam_id, setExam_id] = useState('')
  const [exams, setExams] = useState([])

  useEffect(() => {
    let courseorclassid = localStorage.getItem('student_role') == 'Up to HSC' ? `subject_id=${localStorage.getItem('subject_id')}` : localStorage.getItem('student_role') == 'Undergraduate' ? `course_id=${localStorage.getItem('course_id')}` : ''

    axios.get(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/exam_name/?${courseorclassid}`)
      .then(res => {
        setExams(res.data);
      })
  }, []);

  const columns = [
    {
      name: "Subject",
      selector: (row) => row.subject_name,
      sortable: true,
    },
    {
      name: "Question",
      selector: (row) => row.index,
      sortable: true,
    },
    {
      name: "Category",
      selector: (row) => 'Blooms',
      sortable: true,
    },
    {
      name: "Question Point",
      selector: (row) => row.ques_point,
      sortable: true,
    },
    {
      name: "Marks",
      selector: (row) => row.given_marks != '' ? row.given_marks : 'Invalid',
      sortable: true,
    },
    // {
    //   name: "Action",
    //   cell: (row) => (
    //     <div>
    //       <button
    //         onClick={() => navigate("/Evaluate_Job")}
    //         className="text-white bg-green-500 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-md px-5 py-2.5 text-center me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
    //       >
    //         EVALUATE
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  const [data, setData] = useState([])

  const filteredData = data.filter(
    (item) => {
      // console.log(item);
      return item.stem_desc.toLowerCase().includes(search.toLowerCase()) ||
        item.answer.toLowerCase().includes(search.toLowerCase()) ||
        item.marks.toString().includes(search)
    }
  );

  const fetchMarks = (e) => {
    e.preventDefault()
    axios.get(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/answers?student_id=${localStorage.getItem('user_id')}&exam_id=${exam_id}`)
      .then(res => {
        const result = res.data.reduce((obj, item) => {
          if (!obj.hasOwnProperty(item.stem_id)) {
            obj[item.stem_id] = [];
          }
          // console.log(existingResult[item.ques_id].answer);
          item.given_marks = item.marks != null ? item.marks : ''
          obj[item.stem_id].push(item);

          return obj;
        }, []).filter(res => res != undefined)

        var finalArray = []
        result.map((item, i) => {
          console.log(result);
          item.map(k => {
            // console.log(k);
            finalArray.push({
              answer: k.answer,
              answer_id: k.answer_id,
              domain: k.domain,
              index: i + 1,
              ques_desc: k.ques_desc,
              stem_desc: k.stem_desc,
              ques_marks: k.ques_marks,
              given_marks: k.given_marks,
              ques_point: k.ques_point,
              subject_name: k.subject_name,
            })
          })
        })
        setData(finalArray)
        console.log(finalArray);
      })
  }

  return (
    <div className="Admin bg-gray-100 min-h-screen">
      <Navbar />
      <div className="container mx-auto p-5">
        <div className="bg-white rounded shadow-lg p-6 mt-6">
          <h2 className="text-3xl font-bold text-center text-blue-600 mb-6">
            Exam Results
          </h2>
          <div className="mb-4 flex justify-center items-center">
            <div className="relative w-full max-w-lg">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="text-gray-500" />
              </div>
              <input
                type="text"
                placeholder="Search..."
                className="w-full p-3 pl-10 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className=" flex mb-4">
            <select
              id="chapter"
              value={exam_id}
              onChange={e => setExam_id(e.target.value)}
              className="w-full mr-4 p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select Exam</option>
              {exams.map((exam) => (
                <option key={exam.id} value={exam.exam_id}>
                  {exam.exam_name}
                </option>
              ))}
            </select>
            <button
              onClick={fetchMarks}
              className="text-white bg-blue-500 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-lg transition duration-200 ease-in-out"
            >
              Search
            </button>
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            highlightOnHover
            pointerOnHover
            className="border border-gray-300 rounded"
            customStyles={{
              rows: {
                style: {
                  minHeight: "72px",
                },
              },
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#f7fafc",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default StudentResults;
