import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import Navbar from '../components/Navbar';
import axios from 'axios';

const Question = () => {
    const[cognitive_points, setCognitive_points] = useState([])
    const[clses, setClses] = useState([])
    const[subjects, setSubjects] = useState([])
    const[chapters, setChapters] = useState([])
    const[class_id, setClass_id] = useState('')
    const[subject_id, setSubject_id] = useState('')
    const[chapter_id, setChapter_id] = useState('')
    const[showQues, setShowQues] = useState(false)
    const[question, setQuestion] = useState('')
    const [cognitives, setCognitives] = useState([
        {
            quesPoint: 'a',
            ques: '',
            cognitive_domain: ''
        }
    ])

    const nextChar = (c) => {
        return String.fromCharCode(c.charCodeAt(0) + 1);
    }
    

    useEffect(() => {
        console.log(`${process.env.REACT_APP_NODE_API}`);
        axios.get(`${process.env.REACT_APP_NODE_API}/subject?class_id=${class_id}`,
          {
            headers: {
              authorization: "bearer " + localStorage.getItem("access_token"),
            },
          }).then((response) => {
            setSubjects(response.data);
          });
      }, [class_id]);

      useEffect(() => {
       
        axios.get(`${process.env.REACT_APP_NODE_API}/subject/chapters?subject_id=${subject_id}`,
          {
            headers: {
              authorization: "bearer " + localStorage.getItem("access_token"),
            },
          }).then((response) => {
            console.log(response.data);
            setChapters(response.data);
          });
      }, [subject_id]);
      
    

      useEffect(() => {
        axios.get(`${process.env.REACT_APP_NODE_API}/class`, {
          headers: {
            authorization: "bearer " + localStorage.getItem("access_token"),
          },
        }).then((response) => {
          setClses(response.data);
          console.log(clses)
        });
      }, []);

      useEffect(() => {
        axios.get(`${process.env.REACT_APP_NODE_API}/questions/cognitive_domains`, {
          headers: {
            authorization: "bearer " + localStorage.getItem("access_token"),
          },
        }).then((response) => {
          setCognitive_points(response.data);
          
        });
      }, []);

      const handleQuestion = (e) => {
            e.preventDefault()
            setShowQues(true)
      }

      const handleCognitivesQues = (e, point) => {
            setCognitives(
                cognitives.map(item => {
                    if(item.quesPoint == point){
                        return {...item, ques: e.target.value}
                    }
                    else{
                        return item
                    }
                })
            )
      }

      const handleCognitivesDomains = (e, point) => {
        console.log(cognitives);
        setCognitives(
            cognitives.map(item => {
                if(item.quesPoint == point){
                    return {...item, cognitive_domain: e.target.value}
                }
                else{
                    return item
                }
            })
        )
  }

  const addMoreQues = (e) => {
    e.preventDefault()
    // console.log(cog);
    setCognitives(prev => [...prev, {quesPoint: nextChar(prev.slice(-1)[0].quesPoint), ques: '', cognitive_domain: ''}])
  }

  const submit = (e) => {
    e.preventDefault()

    axios.post(`${process.env.REACT_APP_NODE_API}/questions/add/stem`, {
        stem: question,
        class_id,
        subject_id,
        chapter_id
    }, {
        headers: {
            authorization: "bearer " + localStorage.getItem("access_token"),
          },
    }).then(res => {
        let stem_id = res.data[0].id
        console.log(res.data);
        cognitives.forEach(item => {
            axios.post(`${process.env.REACT_APP_NODE_API}/questions/add/ques`, {
                stem_id,
                ques_point: item.quesPoint,
                description: item.ques,
                domain_id: item.cognitive_domain
            }, {
                headers: {
                    authorization: "bearer " + localStorage.getItem("access_token"),
                  },
            }).then(res2 => {
                console.log(res2.data);
                toast('Submitted')
                setQuestion('')
                setCognitives(
                    [
                        {
                            quesPoint: 'a',
                            ques: '',
                            cognitive_domain: ''
                        }
                    ]
                )
            
            })
        })
        
    })
  }
  return (
    <div>
        <Navbar />
        <ToastContainer/>
        <div className='flex flex-col items-center justify-center'>
            <div className=' w-[70%] rounded shadow-lg mt-4 py-5'>
                <div className='p-5 bg-gray-500'>
                   <h1 className='font-poppins text-xl text-green-300'>Create Question</h1> 
                </div>
                <div className='grid grid-cols-2'>
                    <div className='flex flex-col items-start px-6 pt-3'>
                        <label className='pb-2 text-lg'>Class:</label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            value={class_id}
                            onChange={(e) => setClass_id(e.target.value)}
                        >
                            <option value="">Select</option>
                            {clses.map((item) => {
                            return (
                                <option value={item.id}>
                                {item.class_name}
                                </option>
                            );
                            })}
                        </select>
                    </div>
                    <div className='flex flex-col items-start px-6 pt-3'>
                        <label className='pb-2 text-lg'>Subject:</label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            value={subject_id}
                            onChange={(e) => setSubject_id(e.target.value)}
                        >
                            <option value="">Select</option>
                            {subjects.map((item) => {
                            return (
                                <option value={item.id}>
                                {item.subject_name}
                                </option>
                            );
                            })}
                        </select>
                    </div>
                    <div className='flex flex-col items-start px-6 pt-3'>
                        <label className='pb-2 text-lg'>Chapter:</label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                            value={chapter_id}
                            onChange={(e) => setChapter_id(e.target.value)}
                        >
                            <option value="">Select</option>
                            {chapters.map((chapter) => {
                            return (
                                <option value={chapter.id}>
                                {chapter.chapter_name}
                                </option>
                            );
                            })}
                        </select>
                    </div>
                </div>

                <div className='grid place-items-center mt-4'>
                    <button onClick={(e) => setShowQues(true)} className='bg-slate-500 focus:ring-4 focus:ring-slate-700 text-white px-5 py-3 rounded text-lg'>Submit</button>
                </div>
                
                    
                
            </div>

            {showQues && 
            <>
                <div className=' w-[70%] rounded shadow-lg mt-4 py-5'>
                     <div className='p-5 '>
                        <h1 className='font-poppins text-xl'>Question Description</h1> 
                    </div>
                    <div className='grid place-items-center'>
                        <textarea rows={5} className=' shadow appearance-none border rounded w-[90%] py-2 px-3 focus:outline-none focus:shadow-outline focus:ring-4 focus:ring-blue-600 mb-3' style={{fontSize: '24px', resize: 'none'}} value={question} onChange={(e) => setQuestion(e.target.value)}/>
                    </div>
                    <div className='grid place-items-center'>
                     {
                        cognitives.map(item => (
                            <div className='flex mt-3 w-[80%]'>
                                <h3 className='text-3xl'>{item.quesPoint}.</h3>
                                <input value={item.ques} onChange={e => handleCognitivesQues(e, item.quesPoint)} className='shadow appearance-none border rounded w-full  py-2 px-3 mx-2 focus:outline-none focus:shadow-outline focus:ring-4 grow focus:ring-blue-600 mb-3'/>
                                <select onChange={e => handleCognitivesDomains(e, item.quesPoint)} className='shadow appearance-none border rounded w-96 py-2 px-3 focus:outline-none focus:shadow-outline focus:ring-4 focus:ring-blue-600 mb-3'>
                                    <option value=''>Cognitive Domain</option>
                                    {
                                        cognitive_points.map(item => (
                                            <option value={item.id}>{item.domain}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        ))
                    }   
                    </div>
                    <div className='grid place-items-end mt-4 mr-7'>
                        <button onClick={(e) => addMoreQues(e)} className='bg-slate-500 focus:ring-4 focus:ring-slate-700 text-white px-5 py-3 rounded text-lg'>Add More</button>
                    </div>
                    
                </div>
                <div className='grid place-items-center mt-4 mr-7'>
                        <button onClick={(e) => submit(e)} className='bg-slate-500 focus:ring-4 focus:ring-slate-700 text-white px-5 py-3 rounded text-lg'>Submit</button>
                    </div>
                </>
            }
        </div>
    </div>
  )
}

export default Question