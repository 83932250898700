import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import { FaBookOpen } from "react-icons/fa";
import axios from "axios";

const Bloom = () => {
  const [chapters, setChapters] = useState([]);
  useEffect(() => {
    AOS.init({ duration: "1000" });
  }, []);

  const navigation = useNavigate();

  useEffect(() => {
    fetchChapters();
  }, []);

  const fetchChapters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_API}/chapter/`);
      setChapters(response.data);
      console.log(response.data);
    } catch (error) {
      console.log("Error Fetching Chapter", error);
    }
  }



  return (
    <div className="Admin">
      <Navbar />
      <div className="flex flex-col min-h-screen p-5 bg-gray-100">
        <h1 className="text-4xl font-bold text-center mb-10 text-gray-800 text-blue-700">
          Select Chapter for BLOOM Questions
        </h1>
        <div className="flex flex-wrap justify-center gap-6 mt-6">
          {chapters.map((chapter) => (
            <div
              key={chapter.id}
              className="transition ease-in-out delay-150 max-w-sm w-full rounded-lg shadow-lg bg-gradient-to-r from-green-400 to-green-600 m-3 hover:from-green-500 hover:to-green-700 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center"
              data-aos="fade-up"
              onClick={() => {
                // localStorage.setItem("chapter_name", chapter.chapter_name)
                // localStorage.setItem('chapter_id', chapter.id)
                navigation("/Answer_Bloom_Question");
              }}
            >
              <div className="text-center p-6 flex gap-6">
                <FaBookOpen className="text-white text-4xl mb-4" />
                <h5 className="text-2xl font-bold tracking-tight text-white">
                  {chapter.chapter_name} (BLOOM)
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Bloom;
