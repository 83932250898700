import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import DataTable from "react-data-table-component";

const AddJob = () => {
  const [name, setName] = useState("");
  const [sectors, setSectors] = useState([]);
  const [sector_id, setSector_id] = useState("");
  const [data, setData] = useState([]);
  const [file, setFile] = useState("");
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Sector Name",
      selector: (row) => row.sector_name,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="">
          <button
            className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            onClick={(e) => handleDelete(e, row.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const getData = () => {
    axios.get(`${process.env.REACT_APP_NODE_API}/job/posts`).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getData();
  });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_NODE_API}/job/sectors`).then((res) => {
      setSectors(res.data);
    });
  }, []);

  const submit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_NODE_API}/job/add/post`, {
        sector_id,
        job_name: name,
      })
      .then((res) => {
        console.log(res.data);
        setName("");
        setSector_id("");
        toast("Submitted");
        getData();
      });
  };

  const submitCSV = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", file);
    formData.append("sector_id", sector_id);
    axios
      .post(`${process.env.REACT_APP_NODE_API}/job/posts/csv`, formData)
      .then((res) => {
        toast("Submitted");
      });
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Do you want to delete this?")) {
      axios
        .delete(`${process.env.REACT_APP_NODE_API}/job/posts/delete?id=${id}`)
        .then((res) => {
          getData();
        });
    }
  };

  return (
    <div className="Admin">
      <Navbar />
      <ToastContainer />
      <div className="grid place-items-center">
        <div className="p-5">
          <h1 className="font-poppins text-4xl">Add Job</h1>
        </div>
        <form className="max-w-sm mx-auto">
          <div className="grid grid-cols-1">
            <div className="flex flex-col items-start px-6 pt-3">
              <label
                for="default-input"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Sector
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                value={sector_id}
                onChange={(e) => setSector_id(e.target.value)}
              >
                <option value="">Select</option>
                {sectors.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>
            <div className="flex flex-col items-start px-6 pt-3">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Job Name:
              </label>
              <input
                className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Job Name"
              />
            </div>
          </div>

          <div className="grid place-items-center mt-4 mr-7">
            <button
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={(e) => submit(e)}
            >
              Submit
            </button>
          </div>

          <div className="mt-4">
            <input
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400"
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>

          <div className="grid place-items-center mt-4 mr-7">
            <button
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              onClick={(e) => submitCSV(e)}
            >
              Submit CSV
            </button>
          </div>
        </form>
        <div className="container">
          <div className="">
            <DataTable
              className="z-0 mx-auto border border-gray-300 rounded"
              columns={columns}
              data={data}
              pagination={true}
              striped={true}
              highlightOnHover
              fixedHeader
              customStyles={{
                rows: {
                  style: {
                    minHeight: "72px",
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    backgroundColor: "#f7fafc",
                  },
                },
                cells: {
                  style: {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddJob;
