import React from "react";
import MyFooter from "./MyFooter";
import { motion } from "framer-motion";
import MyNavbar from "./Navbar/MyNavbar";

const Contact = () => {
  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-50 dark:bg-slate-800">
      <MyNavbar />
      <div className="flex-grow">
        <section className="py-16 lg:py-20">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <p className="text-base font-semibold uppercase tracking-wide text-indigo-400 dark:text-purple-200">
                Contact Us
              </p>
              <h2 className="font-heading mb-4 text-3xl sm:text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                Get in Touch
              </h2>
              <p className="max-w-3xl mx-auto text-xl text-gray-600 dark:text-slate-400">
                Promise Tech
              </p>
            </div>
            <div className="flex items-stretch justify-center">
              <div className="grid md:grid-cols-2 gap-8">
                <div className="pr-6">
                  <p className="mb-12 text-lg text-gray-600 dark:text-slate-400">
                    Class aptent taciti sociosqu ad litora torquent per conubia
                    nostra, per inceptos himenaeos. Duis nec ipsum orci. Ut
                    scelerisque sagittis ante, ac tincidunt sem venenatis ut.
                  </p>
                  <ul>
                    <li className="flex mb-8">
                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-900 text-gray-50">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="h-6 w-6"
                        >
                          <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                          <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                        </svg>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                          Our Address
                        </h3>
                        <p className="text-gray-600 dark:text-slate-400">
                          Khaja Super Market, 2 <sup>nd</sup> to 7<sup>th</sup>{" "}
                          Floor, Kallyanpur Bus Stop
                        </p>
                        <p className="text-gray-600 dark:text-slate-400">
                          Mirpur Road, Dhaka-1207.
                        </p>
                      </div>
                    </li>
                    <li className="flex mb-8">
                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-900 text-gray-50">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="h-6 w-6"
                        >
                          <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                          <path d="M15 7a2 2 0 0 1 2 2"></path>
                          <path d="M15 3a6 6 0 0 1 6 6"></path>
                        </svg>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                          Contact
                        </h3>
                        <p className="text-gray-600 dark:text-slate-400">
                          Mobile: +88 01550-666900
                        </p>
                        <p className="text-gray-600 dark:text-slate-400">
                          Gmail: rayat.com
                        </p>
                      </div>
                    </li>
                    <li className="flex">
                      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-900 text-gray-50">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="h-6 w-6"
                        >
                          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                          <path d="M12 7v5l3 3"></path>
                        </svg>
                      </div>
                      <div className="ml-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                          Working hours
                        </h3>
                        <p className="text-gray-600 dark:text-slate-400">
                          Monday - Friday: 08:00 - 17:00
                        </p>
                        <p className="text-gray-600 dark:text-slate-400">
                          Saturday &amp; Sunday: 08:00 - 12:00
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="card h-fit max-w-6xl p-6 bg-white shadow-lg rounded-lg md:p-12 dark:bg-slate-700">
                  <h2 className="mb-4 text-2xl font-bold text-gray-900 dark:text-white">
                    Ready to Get Started?
                  </h2>
                  <form id="contactForm">
                    <div className="space-y-4">
                      <div>
                        <label htmlFor="name" className="sr-only">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          autoComplete="given-name"
                          placeholder="Your name"
                          className="w-full rounded-md border border-gray-400 py-2 px-4 shadow-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:text-gray-300"
                          name="name"
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="sr-only">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          autoComplete="email"
                          placeholder="Your email address"
                          className="w-full rounded-md border border-gray-400 py-2 px-4 shadow-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:text-gray-300"
                          name="email"
                        />
                      </div>
                      <div>
                        <label htmlFor="textarea" className="sr-only">
                          Message
                        </label>
                        <textarea
                          id="textarea"
                          name="textarea"
                          cols="30"
                          rows="5"
                          placeholder="Write your message..."
                          className="w-full rounded-md border border-gray-400 py-2 px-4 shadow-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 dark:text-gray-300"
                        ></textarea>
                      </div>
                    </div>
                    <div className="mt-6 text-center">
                      <button
                        type="submit"
                        className="w-full bg-indigo-500 hover:bg-indigo-800 text-white px-6 py-3 font-xl rounded-md transition-colors duration-300"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="section">
        <div className="text-center max-w-[600px] mx-auto">
          <div className="sm:text-3xl text-2xl font-bold mb-5">
            Subscribe Newsletter
          </div>
          <p className="text-sm leading-7 text-gray">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum autem
            minus doloribus voluptatem illo velit quia eum aperiam! Repudiandae,
            tempore. Lorem ipsum dolor sit amet.
          </p>
          <motion.form
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.3 }}
            className="mt-5"
          >
            <input
              type="text"
              placeholder="Enter your email address"
              className="sm:p-3 p-2 outline-none text-sm shadow-md sm:w-72 w-60"
            />
            <button className="text-sm text-white bg-amber-700 sm:p-3 p-2 shadow-md font-bold">
              Subscribe
            </button>
          </motion.form>
        </div>
      </div>
      <MyFooter />
    </div>
  );
};

export default Contact;
