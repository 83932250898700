import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'
import Question from "./pages/Question";

import 'react-toastify/dist/ReactToastify.css';
import Jobs from "./pages/Jobs";
import AddSector from "./pages/AddSector";
import AddJob from "./pages/AddJob";
import AddSkills from "./pages/AddSkills";
import MapCompetency from "./pages/MapCompetency";
import Register from "./pages/Register";
import Login from "./pages/Login";
import SuperAdminAddAdmin from "./pages/SuperAdminAddAdmin";
import SuperAdmin from "./pages/SuperAdmin";
import CourseAdmin from "./pages/CourseAdmin";
import StudentEnroll from "./pages/StudentEnroll";
import TeacherEnroll from "./pages/TeacherEnroll";
import CreateBatch from "./pages/CreateBatch";
import CreateCourse from "./pages/CreateCourse";
import AssignTeacher from "./pages/AssignTeacher";
import EnrollStudentCourse from "./pages/EnrollStudentCourse";
import StudentPanel from "./pages/StudentPanel";
import TeacherPanel from "./pages/TeacherPanel";
import HomePage from "./components/HomePage";
import HRAdmin from "./pages/HRAdmin";
import Contact from "./components/Contact";
// import ServicesPage from "./components/ServicesPage";
// import AboutPage from "./components/About";
import TeacherPanels from "./TeacherDashboard/TeacherPanels";
import GiveExam from "./TeacherDashboard/GiveExam";
import QuestionType from "./TeacherDashboard/QuestionType";
import MCQ from "./TeacherDashboard/MCQ";
import AnswerOfMcq from "./TeacherDashboard/AnswerOfMcq";
import AddMcqQuestion from "./TeacherDashboard/AddMcqQuestion";
import Bloom from "./TeacherDashboard/Bloom";
import AddBloomChapter from "./TeacherDashboard/AddBloomChapter";
import AnswerBloomQuestion from "./TeacherDashboard/AnswerBloomQuestion";
import StudentPanels from "./StudentDashboard/StudentPanels";
import StudentCourses from "./StudentDashboard/StudentCourses";
import StudentResults from "./StudentDashboard/StudentResults";
import StudentExamDate from "./StudentDashboard/StudentExamDate";
import StudentExam from "./StudentDashboard/StudentExam";
import StudentBloomExam from "./StudentDashboard/StudentBloomExam";
import StudentMcqExam from "./StudentDashboard/StudentMcqExam";
import TeacherExamCreate from "./TeacherDashboard/TeacherExamCreate";
import StudentExamPage from "./TeacherDashboard/StudentExamPage";
import StudentGivenExam from "./TeacherDashboard/StudentGivenExam";
import BloomTotalGivenExam from "./TeacherDashboard/BloomTotalGivenExam";
import StudentAddMarksOnBloom from "./TeacherDashboard/StudentAddMarksOnBloom";
import McqTotalGivenExam from "./TeacherDashboard/McqTotalGivenExam";
import BloomQuestion from "./TeacherDashboard/BloomQuestion";
import AddChapter from "./TeacherDashboard/AddChapter";
import Service from "./components/container/Service";
import AboutPage from "./components/container/AboutPage";
import StudentEvaluateJob from "./StudentDashboard/StudentEvaluateJob";
import SuperAdminAddCourse from "./pages/SuperAdminAddSubject";
import ExamSubjects from "./StudentDashboard/ExamSubjects";
import StudentProgression from "./StudentDashboard/StudentProgression";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/about" element={<AboutPage />} />
        <Route exact path="/Hr_Admin" element={<HRAdmin />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/question" element={<Question />} />
        <Route exact path="/job" element={<Jobs />} />
        <Route exact path="/add-sector" element={<AddSector />} />
        <Route exact path="/add-job" element={<AddJob />} />
        <Route exact path="/add-skill" element={<AddSkills />} />
        <Route exact path="/map-competency" element={<MapCompetency />} />
        <Route exact path="/Super_Admin_Page" element={<SuperAdmin />} />
        <Route
          exact
          path="/Create_Admin_Page"
          element={<SuperAdminAddAdmin />}
        />
        <Route exact path="/Course_Admin_Page" element={<CourseAdmin />} />
        <Route exact path="/Student_Enroll_Page" element={<StudentEnroll />} />
        <Route exact path="/Teacher_Assign_Page" element={<TeacherEnroll />} />
        <Route exact path="/Batch_Create" element={<CreateBatch />} />
        <Route exact path="/Course_Create" element={<CreateCourse />} />
        <Route exact path="/Assign_Teacher" element={<AssignTeacher />} />
        <Route exact path="/Subject_Creation" element={<SuperAdminAddCourse/>} />
        <Route
          exact
          path="/Enroll_Student_Course"
          element={<EnrollStudentCourse />}
        />
        <Route exact path="/Student_Panel" element={<StudentPanel />} />
        <Route exact path="/Teacher_Panel" element={<TeacherPanel />} />

        <Route exact path="/Teacher_Dashboard" element={<TeacherPanels />} />
        <Route exact path="/Give_Exam" element={<GiveExam />} />
        <Route exact path="/Question_Type" element={<QuestionType />} />
        <Route exact path="/MCQ" element={<MCQ />} />
        <Route exact path="/Add_Chapter" element={<AddChapter />} />
        <Route exact path="/Answer_MCQ" element={<AnswerOfMcq />} />
        <Route exact path="/AddMcqQuestion" element={<AddMcqQuestion />} />
        <Route exact path="/BLOOM" element={<Bloom />} />
        <Route exact path="/Add_Bloom_Chapter" element={<AddBloomChapter />} />
        <Route
          exact
          path="/Answer_Bloom_Question"
          element={<AnswerBloomQuestion />}
        />
        <Route exact path="/Exam_Page" element={<StudentExamPage />} />
        <Route exact path="/Exam_Create" element={<TeacherExamCreate />} />
        <Route exact path="/Exam_Given" element={<StudentGivenExam />} />
        <Route
          exact
          path="/Bloom_Exam-Given_Page"
          element={<BloomTotalGivenExam />}
        />
        <Route
          exact
          path="/Student_Add_Marks_Bloom"
          element={<StudentAddMarksOnBloom />}
        />
        <Route
          exact
          path="/MCQ_Exam-Given_Page"
          element={<McqTotalGivenExam />}
        />
        <Route exact path="/AddBloomQuestion" element={<BloomQuestion />} />

        <Route exact path="/Student_Dashboard" element={<StudentPanels />} />
        <Route exact path="/Student_Courses" element={<StudentCourses />} />
        <Route exact path="/Student_Result" element={<StudentResults />} />
        <Route exact path="/Student_Exam_Date" element={<StudentExamDate />} />
        <Route exact path="/Student_Exam" element={<StudentExam />} />
        <Route
          exact
          path="/Student_Bloom_Answer"
          element={<StudentBloomExam />}
        />
        <Route exact path="/Student_MCQ_Answer" element={<StudentMcqExam />} />
        <Route exact path="/Evaluate_Job" element={<StudentEvaluateJob/>} />
        <Route exact path="/exam_subjects" element={<ExamSubjects/>} />
        <Route exact path="/Student_Progress" element={<StudentProgression/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
