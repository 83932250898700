import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

const AnswerOfMcq = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <div className="flex flex-col min-h-screen p-5">
        <div className="flex justify-end mb-5 mt-5">
          <button
            className="text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-lg transition duration-200 ease-in-out"
            onClick={() => navigate("/AddMcqQuestion")}
          >
            Add MCQ Question
          </button>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-8 mt-6">
          <div className="mb-10">
            <h2 className="text-3xl font-bold text-center text-blue-700 mb-8">
              Chapter 1: Multiple Choice Questions
            </h2>
            <p className="text-lg text-gray-600 text-center">
              Review and understand the key concepts through these questions.
            </p>
          </div>
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-800">
              In Laravel, what is the purpose of a migration?
            </h3>
            <div className="ml-5 mt-3 space-y-2">
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">A:</span> To create a new
                database table
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">B:</span> To modify
                existing database tables
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">C:</span> To seed the
                database with initial data
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">D:</span> To create routes
                for the application
              </p>
              <p className="font-bold text-green-600 mt-2 mx-5">
                Correct Option: A
              </p>
            </div>
          </div>
          <hr className="border-t border-gray-200 my-8" />
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-800">
              Which directory contains the configuration files for a Laravel
              project?
            </h3>
            <div className="ml-5 mt-3 space-y-2">
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">A:</span> app
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">B:</span> config
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">C:</span> resources
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">D:</span> public
              </p>
              <p className="font-bold text-green-600 mt-2 mx-5">
                Correct Option: B
              </p>
            </div>
          </div>
          <hr className="border-t border-gray-200 my-8" />
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-800">
              What is the purpose of Laravel's Blade templating engine?
            </h3>
            <div className="ml-5 mt-3 space-y-2">
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">A:</span> To handle routing
                within the application
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">B:</span> To generate HTML
                responses to HTTP requests
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">C:</span> To interact with
                the database
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <span className="mr-2 text-blue-500">D:</span> To simplify the
                creation of views and layouts
              </p>
              <p className="font-bold text-green-600 mt-2 mx-5">
                Correct Option: D
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerOfMcq;
