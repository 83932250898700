import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../components/Navbar";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const CreateCourse = () => {
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const notify = () =>
    toast.success("Course Added Successfully");
  const [course_name, setCourse_Name] = useState("");
  const [course_code, setCourse_Code] = useState("");
  const [courses, setCourses] = useState([]);


  useEffect(() => {
    fetchCourse();
  }, []);


  const fetchCourse = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_API}/course`);
      setCourses(response.data);

    } catch (error) {
      console.log("Error fetching Course", error);
      notifyError("Error fetching chapters");
    }
  };


  const submit = async (e) => {
    e.preventDefault();
    const newCourse = { name: course_name, code: course_code };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_API}/course/add`,
        newCourse
      );

      if (response.status >= 200 && response.status < 300) {
        // Check for 2xx status codes
        notifySuccess("Course added successfully");
        fetchCourse();
        setCourse_Name("");
        setCourse_Code("");
      } else {
        notifyError("Failed to add course");
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      notifyError("Error adding course");
      console.error("Error:", error);
    }
  };



  const columns = [
    {
      name: "Course Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Course Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2">
          <button className="text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-md px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
            Delete
          </button>
        </div>
      ),
    },
  ];
  // const handleClasses = (event) => {
  //   setClasses(event.target.value);
  // };

  const data = [
    {
      course_name: "Software Development",
      course_code: "CSE420",
    },
  ];

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <div className="grid place-items-center bg-gray-100 py-10 px-4">
        <ToastContainer />
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
          <div className="p-5 text-center">
            <h1 className="font-poppins text-4xl font-semibold text-blue-700">
              Create Course
            </h1>
          </div>
          <form onSubmit={submit} className="flex flex-col items-center gap-6">
            {/* <div className="flex flex-col w-full">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Class
              </label>
              <select
                id="class"
                required
                value={classes}
                onChange={(e) => setClasses(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
              >
                <option value="">Select Class</option>
                {classes.map((className) => (
                  <option key={className.id} value={className.class_name}>
                    {className.class_name}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="w-full flex flex-col md:flex-row gap-4">
              <div className="mb-6 w-full">
                <label
                  htmlFor="course-name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Course Name
                </label>
                <input
                  type="text"
                  id="course-name"
                  value={course_name}
                  onChange={(e) => setCourse_Name(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2.5 text-black"
                  placeholder="Enter Course Name"
                />
              </div>
              <div className="mb-6 w-full">
                <label
                  htmlFor="course-code"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Course Code
                </label>
                <input
                  type="text"
                  id="course-code"
                  value={course_code}
                  onChange={(e) => setCourse_Code(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2.5 text-black"
                  placeholder="Enter Course Code"
                />
              </div>
            </div>
            <div className="text-center mt-6">
              <button
                className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-md px-5 py-2.5"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="container mt-10 bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
          <DataTable
            className="mx-auto z-0 border border-gray-300 rounded"
            columns={columns}
            data={courses}
            fixedHeader
            pagination
            customStyles={{
              rows: {
                style: {
                  minHeight: "72px",
                },
              },
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#f7fafc",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateCourse;
