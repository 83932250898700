import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const BloomQuestion = () => {
  const [questions, setQuestions] = useState([
    { quesPoint: 'a', questionA: "", category: "", mark: "", answer: "" },
  ]);
  const [stem, setStem] = useState('');


  const addMoreQuestions = () => {
    setQuestions([
      ...questions,
      { quesPoint: nextChar(questions[questions.length - 1].quesPoint), questionA: "", category: "", mark: "", answer: "" },
    ]);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newQuestions = [...questions];
    newQuestions[index][name] = value;
    setQuestions(newQuestions);
  };

  function nextChar(letter) {
    if (letter == "z") {
      return "a";
    } else if (letter == "Z") {
      return "A";
    } else {
      return String.fromCharCode(letter.charCodeAt(0) + 1);
    }
  }

  const handleRemove = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Questions: ", questions);
  };

  // const [chapters, setChapters] = useState([]);
  // const [selectedChapter, setSelectedChapter] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchChapters();
  }, []);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchChapters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_API}/chapter/`);
      // setChapters(response.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NODE_API}/questions/cognitive_domains`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleChapterChange = (event) => {
    // setSelectedChapter(event.target.value);
  };

  const submit = e => {

    e.preventDefault()
    let data = {
      stem,
      class_id: localStorage.getItem('teacher_role') == 'Up to HSC' ? localStorage.getItem('class_id') : 0,
      course_id: localStorage.getItem('teacher_role') == 'Undergraduate' ? localStorage.getItem('course_id') : 0,
      subject_id: localStorage.getItem('teacher_role') == 'Up to HSC' ? localStorage.getItem('subject_id') : 0,

    };


    axios.post(`${process.env.REACT_APP_NODE_API}/questions/school/add/stem`, data).then(res => {
      console.log(res.data);
      questions.map(question => {
        axios.post(`${process.env.REACT_APP_NODE_API}/questions/school/add/ques`, {
          marks: question.mark,
          description: question.questionA,
          stem_id: res.data[0].id,
          ques_point: question.quesPoint,
          domain_id: question.category,
          correct_answer: question.answer
        }).then(res => {
          console.log(res.data);
          toast.success('Submitted')
        })
      })
    })
  }

  const getQuestionLabel = (index) => {
    return `Add Question ${String.fromCharCode(65 + index)}:`;
  };

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <ToastContainer />
      <div className="flex items-center justify-center bg-gradient-to-r from-blue-50 to-purple-50 py-10 px-4">
        <div className="bg-white p-10 rounded-lg shadow-xl w-full">
          <h2 className="text-3xl font-bold text-center text-blue-700 mb-8">
            Add Blooms Question
          </h2>
          <form onSubmit={submit}>
            {/* <div className="mb-4">
              <label
                htmlFor="chapter"
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                Select Question Chapter:
              </label>
              <select
                id="chapter"
                value={selectedChapter}
                onChange={handleChapterChange}
                className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select a chapter</option>
                {chapters.map((chapter) => (
                  <option key={chapter.id} value={chapter.id}>
                    {chapter.chapter_name}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="mb-6">
              <label className="block text-gray-700 font-semibold mb-2">
                Question Description:
              </label>
              <textarea
                value={stem}
                onChange={(e) => setStem(e.target.value)}
                name="description"
                className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter the question answer here..."
              />
            </div>
            {questions.map((question, index) => (
              <div key={index} className="mb-8">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  <div>
                    <label className="block text-gray-700 font-semibold mb-2">
                      {getQuestionLabel(index)}
                    </label>
                    <div className="flex items-center">
                      <p className="text-bold text-2xl pr-3">
                        {question.quesPoint}.
                      </p>
                      <input
                        type="text"
                        name="questionA"
                        value={question.questionA}
                        onChange={(e) => handleChange(index, e)}
                        className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter question..."
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="category"
                      className="block text-gray-700 font-semibold mb-2"
                    >
                      Bloom's Taxonomy Level:
                    </label>
                    <select
                      id="category"
                      name="category"
                      value={question.category}
                      onChange={(e) => handleChange(index, e)}
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="">Select a Category</option>
                      {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.domain}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700 font-semibold mb-2">
                      Add Question Mark:
                    </label>
                    <input
                      type="text"
                      name="mark"
                      value={question.mark}
                      onChange={(e) => handleChange(index, e)}
                      className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder="Enter mark..."
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Question Answer:
                  </label>
                  <textarea
                    value={question.answer}
                    onChange={(e) => handleChange(index, e)}
                    name="answer"
                    className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter the question description here..."
                  />
                </div>
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => handleRemove(index)}
                    className="mt-4 bg-red-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            <div className="flex gap-4">
              <button
                type="button"
                onClick={addMoreQuestions}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Add More
              </button>
              <button
                type="submit"
                className="bg-purple-500 text-white py-2 px-4 rounded-lg shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BloomQuestion;
