import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const StudentCourses = () => {
          useEffect(() => {
            AOS.init({ duration: "1000" });
          });
          const navigation = useNavigate();
  return (
    <div className="Admin">
      <Navbar />
      <div className=" flex justify-center align-middle items-center mt-6">
        <div className="flex flex-col min-h-screen">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-7 py-7"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Student_Exam_Date")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  Web Development
                </h5>

                <p className="font-normal">Course Code: 201</p>
                <p className="font-normal">Batch: 8</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-7 py-7"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Student_Exam_Date")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">Laravel</h5>

                <p className="font-normal">Course Code: 230</p>
                <p className="font-normal">Batch: 8</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-7 py-7"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Student_Exam_Date")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  Graphics Design
                </h5>

                <p className="font-normal">Course Code: 203</p>
                <p className="font-normal">Batch: 8</p>
              </div>
            </div>
            <div
              className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:bg-blue-400 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center px-7 py-7"
              data-aos="fade-up"
              data-aos-offset="300"
              data-aos-easing="ease-in-sine"
              onClick={() => navigation("/Student_Exam_Date")}
            >
              <div className="text-center p-5">
                <h5 className="text-2xl font-bold tracking-tight">
                  Finance and Management
                </h5>

                <p className="font-normal">Course Code: 402</p>
                <p className="font-normal">Batch: 8</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentCourses
