import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import img2 from "../assets/Untitled design (3).png";
import { Button, Label, Toast } from "flowbite-react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import img3 from '../assets/Photo_Add-RoundedWhite-512.png'
import logo from '../assets/1b828ec0-0e44-4a57-a634-d37bd797e665.jfif'

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [cls, setClass] = useState('');
  const [clses, setClses] = useState([])
  const [course, setCourse] = useState("");
  const [courses, setCourses] = useState([])
  // const [profession, setProfession] = useState("");
  const [role, setRole] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showsPassword, setShowsPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [organization, setOrganization] = useState("")


  const navigate = useNavigate();

  const notify = () => toast("Sign Up Successfully");

  const submit = (e) => {

    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_NODE_API}/user/register`, {
        name,
        email,
        password,
        mobile,
        role: 'Student',
      })
      .then((res) => {
        axios.post(`${process.env.REACT_APP_NODE_API}/student/add`, {
          user_id: res.data.user_id,
          course_id: course,
          class_id: cls,
          institute: organization,
          role,
        }).then(res => {
          toast.success('Registered. Wait for approval')
        })
      })
      .catch((err) => {
        toast(err.response.data.msg);
      });
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_NODE_API}/class`)
      .then(res => {
        setClses(res.data)
      })

    axios.get(`${process.env.REACT_APP_NODE_API}/course`)
      .then(res => {
        setCourses(res.data)
      })
  }, [])



  return (
    <div className="bg-[#F8F8F8] h-[100vh] flex">
      <ToastContainer />
      <div className="container m-auto items-center bg-[#FFFFFF] rounded-lg shadow-md">
        <div className="lg:grid grid-cols-2 gap-5 items-center">
          <div className=" p-4 lg:block hidden">
            <div className="bg-[#F6F6F6] h-[90vh] rounded-sm text-center items-center">
              <img src={img2} className="w-full h-full" alt="" />
            </div>
          </div>
          <div className="text-center">
            <div className="flex justify-center">
              <img src={logo} alt="" className="w-60 h-30 justify-center" />
            </div>
            <div className="text-2xl mt-3">
              <div className="flex items-center justify-center">
                <form className="flex w-80 flex-col gap-2" onSubmit={submit}>
                  <div>
                    <div className="mb-0 block">
                      <Label htmlFor="role" value="Role" />
                    </div>
                    <select
                      id="role"
                      required
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value="">Select Level</option>
                      <option value="Up to HSC">Up to HSC</option>
                      <option value="Under Graduate">Under Graduate</option>
                      <option value="Professional">Professional</option>
                    </select>
                  </div>

                  {role === "Up to HSC" && (
                    <div>
                      <div className="mb-0 block">
                        <Label htmlFor="class" value="Class" />
                      </div>
                      <select
                        id="class"
                        required
                        value={cls}
                        onChange={(e) => setClass(e.target.value)}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Select Class</option>
                        {clses.map((item) => (
                          <option value={item.id}>{item.class_name}</option>
                        ))}
                      </select>
                      <div className="mb-0 block mt-10">
                        <label htmlFor="type" value="Type" />
                      </div>
                      <input
                        id="organization"
                        type="text"
                        value={organization}
                        onChange={(e) => setOrganization(e.target.value)}
                        placeholder="Enter your Institute"
                        required
                        shadow
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                    </div>
                  )}

                  {role === "Under Graduate" && (
                    <div>
                      <div className="mb-0 block">
                        <Label htmlFor="courses" value="Courses" />
                      </div>
                      <select
                        id="courses"
                        required
                        value={course}
                        onChange={(e) => setCourse(e.target.value)}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Select Course</option>
                        {courses.map((item) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                  )}

                  {/*       {role === "Professional" && (
                    <div>
                      <div className="mb-0 block">
                        <Label htmlFor="professional" value="Professional" />
                      </div>
                      <select
                        id="profession"
                        required
                        value={profession}
                        onChange={(e) => setProfession(e.target.value)}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">Select Profession</option>
                        <option value="Course 1">Software Engineer</option>
                        <option value="Course 2">Doctor</option>
                        <option value="Course 3">Network Engineer</option>
                        <option value="Course 5">Data Scientist</option>
                      </select>
                    </div>
                  )}
                */}
                  <div>
                    <div className="mb-0 block">
                      <Label htmlFor="name1" value="Name" />
                    </div>
                    <input
                      id="name1"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter your name..."
                      required
                      shadow
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <div className="mb-0 block">
                      <Label htmlFor="email2" value="Email" />
                    </div>
                    <input
                      id="email2"
                      type="email"
                      placeholder="Enter your email..."
                      required
                      shadow
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <div className="mb-0 block">
                      <Label htmlFor="phone" value="Phone" />
                    </div>
                    <input
                      id="phone"
                      type="text"
                      value={mobile}
                      placeholder="Enter Phone Number"
                      required
                      shadow
                      onChange={(e) => setMobile(e.target.value)}
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <div className="mb-0 block">
                      <Label htmlFor="password2" value="Password" />
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        required
                        shadow
                        placeholder="Password..."
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                      <AiOutlineEye
                        className={`fas fa-eye ${showPassword ? "hidden" : ""
                          } text-gray-200`}
                        onClick={() => setShowPassword(true)}
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                      <AiOutlineEyeInvisible
                        className={`fas fa-eye-slash ${showPassword ? "" : "hidden"
                          } text-gray-200`}
                        onClick={() => setShowPassword(false)}
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="mb-0 block">
                      <Label
                        htmlFor="confirms-password"
                        value="Confirm password"
                      />
                    </div>
                    <div style={{ position: "relative" }}>
                      <input
                        id="repeat-password"
                        type={showsPassword ? "text" : "password"}
                        value={confirmPassword}
                        required
                        shadow
                        placeholder="Confirm Password..."
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      />
                      <AiOutlineEye
                        className={`fas fa-eye ${showsPassword ? "hidden" : ""
                          } text-gray-200`}
                        onClick={() => setShowsPassword(true)}
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                      <AiOutlineEyeInvisible
                        className={`fas fa-eye-slash ${showsPassword ? "" : "hidden"
                          } text-gray-200`}
                        onClick={() => setShowsPassword(false)}
                        style={{
                          position: "absolute",
                          right: "5px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    className="bg-red-500 hover:bg-red-900 mt-5"
                    type="submit"
                  >
                    Register
                  </Button>
                </form>
              </div>
              <div className="text-sm mt-3 mb-5">
                <p>
                  If you have an account then{" "}
                  <Link
                    to="/login"
                    style={{ color: "red", fontSize: "14px" }}
                    className="font-bold"
                  >
                    Login
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
