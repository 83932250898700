import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import axios from 'axios';

const StudentBloomExam = () => {
  const [question, setQuestion] = useState([]);
  const [existingAnswer, setExistingAnswer] = useState({})
  const [exams, setExams] = useState([])
  const [exam_id, setExam_id] = useState('')
  useEffect(() => {
    let courseorclassid = localStorage.getItem('student_role') == 'Up to HSC' ? `subject_id=${localStorage.getItem('subject_id')}` : localStorage.getItem('student_role') == 'Undergraduate' ? `course_id=${localStorage.getItem('course_id')}` : ''

    axios.get(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/exam_name/?${courseorclassid}`)
      .then(res => {
        setExams(res.data);
      })
  }, []);

  // useEffect(() => {
  //   fetchQuestion();
  // }, []);

  const fetchQuestion = async () => {
    try {
      setExistingAnswer([])
      var existingResult = {};

      let courseorclassid = localStorage.getItem('student_role') == 'Up to HSC' ? `subject_id=${localStorage.getItem('subject_id')}&exam_id=${exam_id}` : localStorage.getItem('student_role') == 'Undergraduate' ? `course_id=${localStorage.getItem('course_id')}&exam_id=${exam_id}` : ''

      const response = await axios.get(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/?${courseorclassid}`);
      const data = await response.data

      console.log(data);

      for (var i of data) {
        const res = await axios.get(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/answers?${courseorclassid}&student_id=${localStorage.getItem('user_id')}&ques_id=${i['ques_id']}`)
        const data2 = await res.data
        if (data2.length > 0) {
          for (var k of data2) {
            existingResult[k['ques_id']] = { answer_id: k.answer_id, answer: k.answer }

          }
        }
      }


      const result = response.data.reduce((obj, item) => {
        if (!obj.hasOwnProperty(item.stem_id)) {
          obj[item.stem_id] = [];
        }
        // console.log(Object.keys(existingResult).includes(item.ques_id.toString()) == true);
        console.log(existingResult[item.ques_id]);
        item.answer = Object.keys(existingResult).includes(item.ques_id.toString()) == true ? existingResult[item.ques_id.toString()].answer : '';
        obj[item.stem_id].push(item);

        return obj;
      }, []);

      setQuestion(result);
      setExistingAnswer(existingResult)



    } catch (error) {
      console.log("Error fetching Question", error);
    }
  };

  const addAnswers = (e) => {
    e.preventDefault()

    question.map(i => {
      i.map(j => {
        // console.log(Object.keys(existingAnswer).includes(j.ques_id.toString()), j.ques_id, existingAnswer)
        if (j.answer != '' && Object.keys(existingAnswer).includes(j.ques_id.toString()) == false) {
          axios.post('${process.env.REACT_APP_NODE_API}/exam/school/blooms/answers/add', {
            exam_id: j.exam_id,
            ques_id: j.ques_id,
            student_id: localStorage.getItem('user_id'),
            answer: j.answer
          }).then(res => {
            toast.success('Submitted')
          })
        } else if (j.answer != '' && Object.keys(existingAnswer).includes(j.ques_id.toString()) == true) {
          axios.put(`${process.env.REACT_APP_NODE_API}/exam/school/blooms/answers/update?id=${existingAnswer[j['ques_id']]['answer_id']}`, {
            answer: j.answer
          }).then(res => {
            toast.success('Updated')
          })
        }
      })
    })

  }


  const navigate = useNavigate();
  const notify = () => toast.success("Bloom Exam Completed");
  return (
    <div className="Admin">
      <Navbar />
      <ToastContainer />
      <div className="flex flex-col min-h-screen p-5">
        <div className="bg-white rounded shadow-lg p-6 mt-6">
          <div className=" flex mb-4">
            <select
              id="chapter"
              value={exam_id}
              onChange={e => setExam_id(e.target.value)}
              className="w-full mr-4 p-3 border border-gray-300 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Select Exam</option>
              {exams.map((exam) => (
                <option key={exam.id} value={exam.exam_id}>
                  {exam.exam_name}
                </option>
              ))}
            </select>
            <button
              onClick={fetchQuestion}
              className="text-white bg-blue-500 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-lg transition duration-200 ease-in-out"
            >
              Search
            </button>
          </div>
          <div className="mb-10">
            <h2 className="text-3xl font-semibold text-center text-blue-700 mb-8">
              {localStorage.getItem("chapter_name")}
            </h2>
            {question.map((entry, index1) => (
              <div className="bg-white rounded shadow-lg p-6 mt-6">
                <div key={index1} className="mb-10">
                  <p className="text-2xl text-gray-600 font-bold uppercase">
                    {entry[0].stem_desc}
                  </p>
                </div>
                <div key={index1} className="mb-10">
                  <div className="ml-5 mt-3 space-y-3">
                    {entry.map((item, index) => (
                      <div>
                        <p className="text-gray-700 mx-5 bg-gray-100 p-4 rounded shadow-sm text-xl">
                          ({item.ques_point}) {item.ques_desc} [{item.ques_marks}{" "}
                          marks] [{item.domain}]
                        </p>
                        <form class="w-full mx-auto ml-5">
                          <textarea
                            defaultValue={item.answer}
                            onChange={e => {
                              let clone = question
                              clone[index1][index].answer = e.target.value
                              // console.log(clone[index1][index].answer);
                              setQuestion(clone)
                            }}
                            id="message"
                            rows="4"
                            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Question Answer..."
                          ></textarea>

                        </form>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="mx-auto flex justify-start items-start mt-10">
            <button
              onClick={addAnswers}
              className="text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-lg transition duration-200 ease-in-out"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentBloomExam
