import React, { useState } from "react";
import { FaBars, FaBookOpen } from "react-icons/fa";
import { HiXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import logo from "../assets/1b828ec0-0e44-4a57-a634-d37bd797e665-removebg-preview.png";
import '../components/Navbar/navbarlogo.css'

const Navbar = () => {
  const navigate = useNavigate();
    let Links = [
      //  { name: "HOME", link: "/" },
      //  { name: "SERVICE", link: "/service" },
      //  { name: "ABOUT", link: "/about" },
      //  { name: "CONTACT", link: "/contact" },
     ];
     let [open, setOpen] = useState(false);

  return (
    <div className="shadow-md w-full">
      <div className="md:flex items-center justify-between bg-blue-900 py-4 md:px-10 px-7">
        {/* logo section */}
        <div className="text-xs cursor-pointer flex items-center gap-1">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img src={logo} alt="" className="navbar-logo " />
            </div>
            <div className="ml-4">
              <h1 className="text-xl font-bold">CogniPath</h1>
              <p className="text-xs">STUDENT CAREER NAVIGATOR</p>
            </div>
          </div>
        </div>
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7 z-auto"
        >
          {open ? <HiXMark /> : <FaBars />}
        </div>
        <ul
          className={`md:flex md:items-center md:pb-0 pb-40 absolute md:static z-20 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-12" : "top-[-490px]"
          }`}
        >
          {Links.map((link) => (
            <li className="md:ml-8 md:my-0 my-7 font-semibold">
              <a
                href={link.link}
                className="text-gray-800 hover:text-blue-400 duration-500"
              >
                {link.name}
              </a>
            </li>
          ))}
          {!localStorage.getItem("token") ? (
            <div className="flex">
              <div className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm text-center">
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="p-2"
                >
                  Login
                </button>
              </div>
              <div className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm text-center">
                <button
                  onClick={() => {
                    navigate("/register");
                  }}
                  className="p-2"
                >
                  Register
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-sm text-center bg-blue-900">
                <button
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("role");
                    localStorage.removeItem("user_id");
                    navigate("/login");
                  }}
                  className="p-2"
                >
                  Logout
                </button>
              </div>
            </div>
          )}
        </ul>
        {/* button */}
      </div>
    </div>
  );
};

export default Navbar;
