import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";

const AddSector = () => {
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [file, setFile] = useState("");
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="">
          <button
            className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            onClick={(e) => handleDelete(e, row.id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const getData = () => {
    axios.get(`${process.env.REACT_APP_NODE_API}/job/sectors`).then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const submit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_NODE_API}/job/add/sector`, {
        sector_name: name,
      })
      .then((res) => {
        console.log(res.data);
        setName("");
        toast("Submitted");
      });
  };

  const submitCSV = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(`${process.env.REACT_APP_NODE_API}/job/sectors/csv`, formData)
      .then((res) => {
        toast("Submitted");
        getData();
      });
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    if (window.confirm("Do you want to delete this?")) {
      axios
        .delete(`${process.env.REACT_APP_NODE_API}/job/sectors/delete?id=${id}`)
        .then((res) => {
          getData();
        });
    }
  };

  return (
    <div className="Admin">
      <Navbar />
      <ToastContainer />
      <div className="grid place-items-center">
        <div className="p-5">
          <h1 className="font-poppins text-4xl">Add Sector</h1>
        </div>
        <div className="flex flex-col items-start px-6 pt-3">
          <div className="mb-6">
            <label
              for="default-input"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Sector Name
            </label>
            <input
              type="text"
              id="default-input"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
              placeholder="Enter Sector Name"
            />
          </div>
          {/*  <label className="pb-2 text-lg">Sector Name:</label>
          <input
            className="shadow appearance-none border rounded w-[90%] py-2 px-3 focus:outline-none focus:shadow-outline focus:ring-4 focus:ring-blue-600 mb-"
            value={name}
            onChange={(e) => setName(e.target.value)}
  /> */}
        </div>
        <div className="grid place-items-center mt-4 mr-7">
          <button
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={(e) => submit(e)}
          >
            Submit
          </button>
        </div>
        <div className="mt-4">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Upload file
          </label>
          <input
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:placeholder-gray-400"
            id="file_input"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          {/*    <input
            className="shadow appearance-none border rounded w-[90%] py-2 px-3 focus:outline-none focus:shadow-outline focus:ring-4 focus:ring-blue-600 mb-"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
  /> */}
        </div>

        <div className="grid place-items-center mt-4 mr-7">
          <button
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={(e) => submitCSV(e)}
          >
            Submit CSV
          </button>
        </div>
        <div className="container">
          <div className="">
            <DataTable
              className="mx-auto z-0 border border-gray-300 rounded"
              columns={columns}
              data={data}
              fixedHeader
              pagination
              customStyles={{
                rows: {
                  style: {
                    minHeight: "72px",
                  },
                },
                headCells: {
                  style: {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    backgroundColor: "#f7fafc",
                  },
                },
                cells: {
                  style: {
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSector;
