import React from "react";
import image2 from "../../assets/teacher2.png";
import { SlGraduation } from "react-icons/sl";
import { FiVideo } from "react-icons/fi";
import { SlPeople } from "react-icons/sl";

const Achievement = () => {
  return (
    <div className="w-full py-24 section">
      <div className="md:max-w-[1480px] m-auto grid md:grid-cols-2 max-w-[600px] px-4 md:px-0 gap-8">
        <div className="flex flex-col justify-center">
          <h1 className="md:leading-[72px] text-4xl font-bold text-gray-800">
            Our <span className="text-amber-500">Achievements</span>
          </h1>
          <p className="text-lg text-gray-600 mt-4">
            Over the years, we have continuously evolved, achieving remarkable
            milestones.
          </p>

          <div className="grid grid-cols-2 gap-8 py-16">
            <div className="py-6 flex items-center animate-fade-in">
              <div className="p-4 bg-[#E9F8F3] rounded-xl shadow-lg">
                <SlGraduation size={30} style={{ color: "#1A906B" }} />
              </div>
              <div className="px-3">
                <h1 className="text-3xl font-semibold text-gray-800">300</h1>
                <p className="text-[#6D737A]">Instructors</p>
              </div>
            </div>
            <div className="py-6 flex items-center animate-fade-in delay-100">
              <div className="p-4 bg-[#FFFAF5] rounded-xl shadow-lg">
                <FiVideo size={30} style={{ color: "#FFC27A" }} />
              </div>
              <div className="px-3">
                <h1 className="text-3xl font-semibold text-gray-800">
                  10,000+
                </h1>
                <p className="text-[#6D737A]">Courses</p>
              </div>
            </div>
            <div className="py-6 flex items-center animate-fade-in delay-200">
              <div className="p-4 bg-[#FFEEF0] rounded-xl shadow-lg">
                <SlGraduation size={30} style={{ color: "#ED4459" }} />
              </div>
              <div className="px-3">
                <h1 className="text-3xl font-semibold text-gray-800">
                  20,000+
                </h1>
                <p className="text-[#6D737A]">Students</p>
              </div>
            </div>
            <div className="py-6 flex items-center animate-fade-in delay-300">
              <div className="p-4 bg-[#F0F7FF] rounded-xl shadow-lg">
                <SlPeople size={30} style={{ color: "#0075FD" }} />
              </div>
              <div className="px-3">
                <h1 className="text-3xl font-semibold text-gray-800">
                  100,000+
                </h1>
                <p className="text-[#6D737A]">Users</p>
              </div>
            </div>
          </div>
        </div>

        <img
          src={image2}
          className="m-auto md:order-last order-first rounded-xl shadow-lg animate-fade-in h-full"
        />
      </div>
    </div>
  );
};

export default Achievement;
