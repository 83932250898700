import React from 'react';
import { categories, courses } from '../../../Data';
import Categories from './Categories';
import Course from './Course';
import TopCourses from '../TopCourses';

const Courses = () => {

  return (
    <div className="section" id="courses">
      <div className="text-center mb-12">
        <h2 className="sm:text-3xl text-2xl font-bold mb-5">
          Our Top <span className="text-amber-600">Categories</span>
        </h2>
        <p className="text-sm text-gray leading-7 max-w-[700px] mx-auto">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore
          tempora illo laborum ex cupiditate tenetur doloribus non velit atque
          amet repudiandae ipsa modi numquam quas odit optio, totam voluptate
          sit! Lorem ipsum dolor sit amet.
        </p>
      </div>

      <div className="grid md:grid-cols-4 sm:grid-cols-1 gap-8 mb-12">
        {categories.map((category) => (
          <Categories key={category.id} {...category} />
        ))}
      </div>
      <TopCourses />
      <div className="text-xl font-bold mb-5">All of Our Courses</div>

      <div className="relative overflow-x-hidden w-full mb-12">
        <div className="flex gap-2 md:w-full sm:w-[170%] xs:w-[340%] w-[480%]">
          {courses.map((course) => (
            <Course key={course.id} {...course} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Courses;