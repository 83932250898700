import React, { useEffect } from 'react'
import Navbar from '../components/Navbar';
import CreateQuestion from '../assets/exam-ians.jpg'
import CreateExam from '../assets/term-q-questions-answers-written-blackboard-term-q-questions-answers-written-chalkboard-146991764.png'
import StudentReport from "../assets/online_education.jpg";
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";

const TeacherPanels = () => {
  useEffect(() => {
    AOS.init({ duration: "1000" });
  });
  const navigation = useNavigate();
  return (
    <div className="Admin">
      <Navbar />
      <div className="flex flex-col min-h-screen justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={CreateQuestion}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Question Create
              </h5>

              <p class="mb-3 font-normal">
                {" "}
                In this Panel Give Exam to get Evaluate
              </p>
              <button
                onClick={() => navigation("/Question_Type")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Click
              </button>
            </div>
          </div>

          <div
            className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={CreateExam}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Check Marks
              </h5>

              <p class="mb-3 font-normal">In this Panel Check your Marks</p>
              <button
                onClick={() => navigation("/Student_Add_Marks_Bloom")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Click
              </button>
            </div>
          </div>

          <div
            className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover"
              src={StudentReport}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Exam Create
              </h5>

              <p class="mb-3 font-normal">
                In this Panel You can add and create exam
              </p>
              <button
                onClick={() => navigation("/Exam_Create")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Click
              </button>
            </div>
          </div>

          {/*        <div
                  onClick={() => navigation("/add-job")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Job Positions
                  </h2>
</div> */}

          {/*        <div
                  onClick={() => navigation("/add-skill")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Set Competency
                  </h2>
</div> */}
        </div>
      </div>
    </div>
  );
}

export default TeacherPanels
