import React from 'react';
import hero from '../../assets/our-progms-csit-removebg-preview.png';
import {
  FaCode,
  FaLaptopCode,

  FaPalette,

} from 'react-icons/fa';
import { AiOutlineLike } from 'react-icons/ai';
import { IoIosPeople } from 'react-icons/io';
import OurMission from './OurMission';
import aboutFrom from '../../assets/about.jpg';

const HomeDetails = () => {
  const service = [
    {
      id: 1,
      title: 'UI/UX Designer',
      describe:
        'Craft user interfaces that are intuitive, beautiful, and user-friendly.',
      icon: <FaPalette className="text-4xl mx-auto text-violet-700" />,
    },
    {
      id: 2,
      title: 'Front-End Engineer',
      describe:
        'Bring websites and applications to life using JavaScript, HTML, and CSS.',
      icon: <FaCode className="text-4xl mx-auto text-blue-700" />,
    },
    {
      id: 3,
      title: 'Full-Stack Developer (Frontend Focus)',
      describe:
        'Build both the front-end and back-end of applications, with an emphasis on user interfaces.',
      icon: <FaLaptopCode className="text-4xl mx-auto text-teal-700" />,
    },
  ];
  return (
    <div className="section" id="home">
      <div className="md:flex items-center justify-center space-y-8 md:space-y-0 md:space-x-8 p-8">
        <div className="max-w-md">
          <div className="font-bold text-sm text-amber-700 mb-4 uppercase tracking-wider">
            Your career choice partner
          </div>
          <div className="sm:text-[2.5rem] text-[1.825rem] font-bold leading-tight text-gray-900">
            This is <br /> the new way <br /> to learn and choose your career
          </div>
          <p className="text-sm leading-7 text-gray-600 mt-4">
            find and choose your career with our site and choose your career with CogniPath
          </p>
          <div className="mt-6 flex space-x-4">
            <button className="px-6 py-3 font-bold text-white rounded-lg text-sm bg-gradient-to-r from-red-500 via-amber-700 to-yellow-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-red-100 dark:focus:ring-red-400">
              Get Started
            </button>
            <button className="px-6 py-3 font-bold border border-solid border-gray-300 rounded-lg text-sm text-gray-900 hover:bg-gray-100">
              Discover
            </button>
          </div>
        </div>
        <div className="md:w-[50%]">
          <img
            src={hero}
            alt="Hero image"
            className="w-full rounded-lg shadow-lg"
          />
        </div>
      </div>

      <div>
        <div className="bg-gradient-to-r from-gray-100 via-white to-gray-100 py-12">
          <h1
            className="text-center text-5xl font-bold text-gray-800 mb-12 animate-fadeIn"
            data-aos="fade-up"
          >
            About Us
          </h1>
          <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto">
            <div className="md:w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
              <div className="w-full md:w-1/2" data-aos="fade-right">
                <img
                  src={aboutFrom}
                  alt="About Us"
                  className="w-full h-auto rounded-lg shadow-lg animate-zoomIn"
                />
              </div>
              <div
                className="md:w-3/5 mx-auto bg-white p-8 rounded-lg shadow-lg animate-fadeInRight"
                data-aos="fade-left"
              >
                <p className="text-3xl text-amber-600 font-semibold mb-4">
                  Why Choose Our Service
                </p>
                <h2 className="text-4xl text-stone-700 font-semibold mb-6">
                  We Are Professional Logistics & Cargo Agency
                </h2>
                <p className="text-lg text-gray-700 leading-relaxed">
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. A
                  explicabo ad sint qui aut, totam obcaecati beatae quidem rem
                  illum dolorum cum, at facere, doloremque ipsum iure dolore
                  minima! Reiciendis optio debitis saepe odio ex iste nemo
                  aspernatur magnam amet. Perspiciatis quos nostrum, 
                </p>
              </div>
            </div>
          </div>
          
          <div>
            <OurMission />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center my-8">
          <h2
            className="text-4xl text-stone-800 font-semibold mb-2"
            data-aos="fade-up"
          >
            Our Clients
          </h2>
          <p className="text-gray-600 mb-10" data-aos="fade-up">
            We are working with some 20 Fortune clients
          </p>

          <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-12 gap-y-4 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/transistor-logo-gray-900.svg"
              alt="Transistor"
              width="158"
              height="48"
              data-aos="fade-up"
              data-aos-delay="100"
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/reform-logo-gray-900.svg"
              alt="Reform"
              width="158"
              height="48"
              data-aos="fade-up"
              data-aos-delay="200"
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/tuple-logo-gray-900.svg"
              alt="Tuple"
              width="158"
              height="48"
              data-aos="fade-up"
              data-aos-delay="300"
            />
            <img
              className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/savvycal-logo-gray-900.svg"
              alt="SavvyCal"
              width="158"
              height="48"
              data-aos="fade-up"
              data-aos-delay="400"
            />
            <img
              className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
              src="https://tailwindui.com/img/logos/158x48/statamic-logo-gray-900.svg"
              alt="Statamic"
              width="158"
              height="48"
              data-aos="fade-up"
              data-aos-delay="500"
            />
          </div>
        </div>
      </div>
      <div className="mt-20 md:w-1/2 mx-auto text-center">
        <h2
          className="text-4xl text-stone-800 font-semibold mb-3"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          Services We Provide
        </h2>
        <p className="text-gray-600" data-aos="fade-up" data-aos-delay="200">
          Explore the variety of services we offer to our clients
        </p>
      </div>
      <div
        className="mt-14 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-12 md:w-11/12 mx-auto"
        data-aos="fade-up"
      >
        {service.map((service) => (
          <div key={service.id}>
            <div className="px-4 py-8 text-center md:w-[300px] mx-auto md:h-80 rounded-md shadow-lg transform transition-transform hover:-translate-y-2 duration-300 border border-gray-200 hover:bg-teal-200 hover:text-white">
              <div className="bg-white p-2 rounded-full w-16 h-16 mx-auto mb-4 shadow-inner">
                {service.icon}
              </div>
              <h4 className="text-2xl font-bold text-stone-800 mb-2 hover:text-white">
                {service.title}
              </h4>
              <p className="text-gray-600 hover:text-white">
                {service.describe}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeDetails;
