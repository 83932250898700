import React from 'react'
import {
  About,
  TeacherPage,
  Courses,
  MyNavbar,
  HomeDetails,
  MyFooter,
  ContactPage,
  Achievement,
} from "../components/index.js";
const HomePage = () => {
  return (
    <div className="font-Poppins bg-Solitude">
      <MyNavbar />
      <HomeDetails />
      <About />
      <Courses />
      <Achievement/>
      <TeacherPage />
      <ContactPage />
      <MyFooter />
    </div>
  );
}

export default HomePage
