import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { FaBookOpen, FaBars } from "react-icons/fa";
import { HiXMark } from "react-icons/hi2";
import logo from "../../assets/1b828ec0-0e44-4a57-a634-d37bd797e665.jfif"
import './navbarlogo.css'

const MyNavbar = () => {
  const navigate = useNavigate();
  let Links = [
    { name: "HOME", link: "/" },
    { name: "SERVICE", link: "/service" },
    { name: "ABOUT", link: "/about" },
    { name: "CONTACT", link: "/contact" },
  ];
  let [open, setOpen] = useState(false);

  return (
    <div className="shadow-md w-full bg-white sticky top-0 z-50">
      <div className="md:flex items-center justify-between py-4 md:px-10 px-7">
        {/* logo section */}
        <div className="font-bold text-2xl cursor-pointer flex items-center gap-2 text-black">
          <img src={logo} alt="" className="navbar-logo" />
          {/* <FaBookOpen className="w-8 h-8" />
          <span>Cogni Path</span> */}
        </div>
        {/* Menu icon */}
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer md:hidden w-8 h-8 text-black"
        >
          {open ? <HiXMark /> : <FaBars />}
        </div>
        {/* link items */}
        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static z-20 left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-12 bg-white" : "top-[-490px]"
          }`}
        >
          {Links.map((link) => (
            <li key={link.name} className="md:ml-8 md:my-0 my-7 font-semibold">
              <a
                href={link.link}
                className="text-black hover:text-blue-600 duration-500"
              >
                {link.name}
              </a>
            </li>
          ))}
          <Link to="/login">
            <button className="btn bg-amber-600 text-white hover:bg-amber-700 md:ml-8 font-semibold px-5 py-2 rounded duration-500 md:static">
              Get Started
            </button>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default MyNavbar;
