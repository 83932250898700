import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../components/Navbar";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Label } from "flowbite-react";

const AssignTeacher = () => {
  const notify = () => toast.success("Teacher Assigned Successfully");
  const [teacher_name, setTeacherName] = useState("");
  const [courseName, setCourseName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState('')
  const [roles, setRoles] = useState([
    'Up to HSC',
    'Undergraduate',
    'Professional'
  ])

  const [courses, setCourses] = useState([]);
  const [cls, setCls] = useState('')
  const [clses, setClses] = useState([])
  const [subjects, setSubjects] = useState([])
  const [subject_id, setSubject_id] = useState('')

  const columns = [
    {
      name: "Teacher Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Teacher Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Teacher Phone Number",
      selector: (row) => row.mobile_no,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.teacher_role,
      sortable: true,
    },


  ];

  const [data, setData] = useState([])

  const fetchCourse = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_API}/course`);
      setCourses(response.data);

    } catch (error) {
      console.log("Error fetching Course", error);
    }
  };


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_NODE_API}/class`)
      .then(res => {
        setClses(res.data)
      })
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_NODE_API}/class/subject?class_id=${cls}`)
      .then(res => {
        setSubjects(res.data)
      })
  }, [cls])

  const getTeachers = () => {
    axios.get(`${process.env.REACT_APP_NODE_API}/teacher/`)
      .then(res => {
        setData(res.data)
      })
  }

  const addTeacher = (e) => {
    e.preventDefault()

    axios.post(`${process.env.REACT_APP_NODE_API}/user/register`, {
      name: teacher_name,
      email: email,
      mobile: phone,
      password: password,
      role: 'Teacher'
    }).then(res => {
      axios.post(`${process.env.REACT_APP_NODE_API}/teacher/add`, {
        course_id: courseName,
        user_id: res.data.user_id,
        role,
        class_id: cls,
        subject_id
      }).then(res => {
        toast.success('Teacher Added')
        getTeachers()
      })
    }).catch(err => {
      toast.error(err.response.data.msg)
    })
  }

  useEffect(() => {
    fetchCourse()
    getTeachers()
  }, [])

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <ToastContainer />
      <div className="grid place-items-center justify-center bg-gray-100 py-10 px-4">
        <div className="container bg-white shadow-lg rounded-lg p-6">
          <div className="p-5 text-center">
            <h1 className="font-poppins text-4xl font-semibold text-blue-700">
              Assign Teacher
            </h1>
          </div>
          <form className="max-w-lg mx-auto" onSubmit={addTeacher}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="mb-6">
                <label
                  htmlFor="teacherName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Teacher Name
                </label>
                <input
                  type="text"
                  id="teacherName"
                  value={teacher_name}
                  onChange={(e) => setTeacherName(e.target.value)}
                  placeholder="Enter Teacher Name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Teacher Email
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter Teacher Email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="phone-input"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Phone Number
                </label>
                <input
                  type="phone-input"
                  id="phone-input"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter Phone Number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                />
              </div>
              <div className="mb-6">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                />
              </div>

              <div className="flex flex-col">
                <label className="block mb-2 text-sm font-medium text-gray-900">
                  Role
                </label>
                <select
                  id="class"
                  required
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
                >
                  <option value="">Select Role</option>
                  {roles.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full md">
                <label
                  htmlFor="course-name"
                  className="block mb-2 text-sm font-medium text-gray-900 justify-center"
                >
                  {role == "Up to HSC"
                    ? "Class"
                    : role == "Undergraduate"
                      ? "Course"
                      : ""}
                </label>
                {role != "" && (
                  <select
                    id="course-name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                    value={
                      role == "Up to HSC"
                        ? cls
                        : role == "Undergraduate"
                          ? courseName
                          : ""
                    }
                    onChange={(e) => {
                      if (role == "Up to HSC") {
                        setCls(e.target.value);
                      } else if (role == "Undergraduate") {
                        setCourseName(e.target.value);
                      }
                    }}
                  >
                    <option value="">
                      Select{" "}
                      {role == "Up to HSC"
                        ? "Class"
                        : role == "Undergraduate"
                          ? "Course"
                          : ""}
                    </option>
                    {role == "Undergraduate"
                      ? courses.map((course) => (
                        <option value={course.id}>{course.name}</option>
                      ))
                      : role == "Up to HSC"
                        ? clses.map((item) => (
                          <option value={item.id}>{item.class_name}</option>
                        ))
                        : ""}
                  </select>
                )}
              </div>
            </div>
            {role === "Up to HSC" && (
              <div>
                <div className="mb-0 block">
                  <Label htmlFor="subject" value="Subject" />
                </div>
                <select
                  id="subject"
                  required
                  value={subject_id}
                  onChange={(e) => setSubject_id(e.target.value)}
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="">Select Subject</option>
                  {subjects.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </select>
              </div>
            )}
            <div className="flex justify-center mb-6"></div>
            <div className="text-center mt-6">
              <button
                className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-md px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="container mt-10 bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
          <DataTable
            className="mx-auto z-0 border border-gray-300 rounded"
            columns={columns}
            data={data}
            fixedHeader
            pagination
            customStyles={{
              rows: {
                style: {
                  minHeight: "72px",
                },
              },
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#f7fafc",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignTeacher;
