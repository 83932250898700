import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import DataTable from "react-data-table-component";
import "./MapCompetency.css"; 

const MapCompetency = () => {
  const [name, setName] = useState("");
  const [cognitive_points, setCognitive_points] = useState([]);
  const [domain_id, setDomain_id] = useState("");
  const [sectors, setSectors] = useState([]);
  const [sector_id, setSector_id] = useState("");
  const [jobs, setJobs] = useState([]);
  const [job_id, setJob_id] = useState("");
  const [skills, setSkills] = useState([]);
  const [skill_id, setSkill_id] = useState("");
  const [data, setData] = useState([]);
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
    },
    {
      name: "Sector Name",
      selector: (row) => row.sector_name,
    },
    {
      name: "Post Name",
      selector: (row) => row.job_name,
    },
    {
      name: "Competency Name",
      selector: (row) => row.skill_name,
    },
    {
      name: "Cognitive Domain",
      selector: (row) => row.domain,
    },
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_NODE_API}/job/competency`)
      .then((res) => {
        setData(res.data);
      });
  }, []);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_NODE_API}/job/sectors`).then((res) => {
      setSectors(res.data);
    });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_NODE_API}/job/posts/filter?sector_id=${sector_id}`
      )
      .then((res) => {
        setJobs(res.data);
      });
  }, [sector_id]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_NODE_API}/job/skills/filter?job_id=${job_id}`
      )
      .then((res) => {
        setSkills(res.data);
      });
  }, [job_id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_NODE_API}/questions/cognitive_domains`, {
        headers: {
          authorization: "bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        setCognitive_points(response.data);
      });
  }, []);

  const submit = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_NODE_API}/job/add/competency`, {
        skill_id,
        domain_id,
      })
      .then((res) => {
        console.log(res.data);
        setName("");
        setSector_id("");
        setJob_id("");
        setSkill_id("");
        setDomain_id("");
        toast("Submitted");
      });
  };

  return (
    <div className="Admin">
      <Navbar />
      <ToastContainer />
      <div className="grid place-items-center">
        <div className="p-5">
          <h1 className="font-poppins text-4xl">Add Skills</h1>
        </div>
        <form className="max-w-lg mx-auto">
          <div className="grid grid-cols-2">
            <div className="flex flex-col items-start px-6 pt-3">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Sector
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                value={sector_id}
                onChange={(e) => setSector_id(e.target.value)}
              >
                <option value="">Select</option>
                {sectors.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>
            <div className="flex flex-col items-start px-6 pt-3">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Job
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                value={job_id}
                onChange={(e) => setJob_id(e.target.value)}
              >
                <option value="">Select</option>
                {jobs.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>
            <div className="flex flex-col items-start px-6 pt-3">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Skill
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                value={skill_id}
                onChange={(e) => setSkill_id(e.target.value)}
              >
                <option value="">Select</option>
                {skills.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </div>
            <div className="flex flex-col items-start px-6 pt-3">
              <label className="block mb-2 text-md font-medium text-gray-900">
                Cognitive Domain
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5"
                value={domain_id}
                onChange={(e) => setDomain_id(e.target.value)}
              >
                <option value="">Select</option>
                {cognitive_points.map((item) => {
                  return <option value={item.id}>{item.domain}</option>;
                })}
              </select>
            </div>
          </div>
        </form>
        <div className="grid place-items-center mt-4 mr-7">
          <button
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-md px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={(e) => submit(e)}
          >
            Submit
          </button>
        </div>

        <div className="container mt-5">
          <div className="responsive-table">
            <DataTable
              className="z-0 mx-auto"
              columns={columns}
              data={data}
              pagination={true}
              highlightOnHover={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapCompetency;
