import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const AddChapter = () => {
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const notify = () => toast.error("Deleted Successfully");

  const [chapters, setChapters] = useState([]);
  const [chapterName, setChapterName] = useState("");

  useEffect(() => {
    fetchChapters();
  }, []);

  const fetchChapters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_API}/chapter/`);
      setChapters(response.data);
    } catch (error) {
      console.error("Error fetching chapters:", error);
      notifyError("Error fetching chapters");
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    const newChapter = { chapter_name: chapterName, course_id: localStorage.getItem('course_id') };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_API}/chapter/add`,
        newChapter
      );
      if (response.status === 200) {
        notifySuccess("Chapter added successfully");
        fetchChapters();
        setChapterName("");
      } else {
        notifyError("Failed to add chapter");
      }
    } catch (error) {
      notifyError("Error adding chapter");
      console.error("Error:", error);
    }
  };

  const columns = [
    {
      name: "Chapter Name",
      selector: (row) => row.chapter_name,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          onClick={notify}
          className="text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-md px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
        >
          Delete
        </button>
      ),
    },
  ];

  return (
    <div className="Admin">
      <Navbar />
      <div className="flex flex-col items-center justify-center p-5 min-h-screen">
        <form
          onSubmit={submit}
          className="w-full bg-white shadow-lg rounded-lg px-8 pt-6 pb-8 mb-4 container"
        >
          <div className="mb-5">
            <h3 className="text-3xl font-bold text-center text-blue-700 mb-10">
              ADD QUESTION CHAPTER NAME
            </h3>
          </div>
          <div className="mb-4">
            <label
              htmlFor="chapterName"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Chapter Name:
            </label>
            <input
              type="text"
              id="chapterName"
              value={chapterName}
              onChange={(e) => setChapterName(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Enter chapter Name"
              required
            />
          </div>
          <button
            type="submit"
            className="text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            Add
          </button>
        </form>
        <div className="mt-10">
          <DataTable
            className="mx-auto bg-white rounded-lg shadow-md"
            columns={columns}
            data={chapters}
            pagination
          />
        </div>
      </div>
    </div>
  );
};

export default AddChapter;
