import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import axios from "axios";

const columns = [

  {
    name: "Subject Name",
    selector: (row) => row.name,
  },
  {
    name: "Class",
    selector: (row) => row.class_name,
  },
  {
    name: "Actions",
    cell: (row) => (
      <button className="text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2">
        Delete
      </button>
    ),
  },
];

const SuperAdminAddCourse = () => {
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const [subjects, setSubjects] = useState([]);
  const [classes, setClasses] = useState([]);
  const [cls, setCls] = useState("");
  const [subject_name, setSubjectName] = useState("")
  useEffect(() => {
    fetchClass();
  }, []);

  useEffect(() => {
    fetchSubject();
  }, []);

  const submit = async (e) => {
    e.preventDefault();
    const newSubject = { name: subject_name, class_id: cls };
    try {
      const response = await axios.post(`${process.env.REACT_APP_NODE_API}/class/subject/add`,
        newSubject
      );
      if (response.status >= 200 && response.status < 300) {
        // Check for 2xx status codes
        notifySuccess("Subject added successfully");
        fetchSubject();
        setSubjectName("");
        setCls("");
      } else {
        notifyError("Failed to add course");
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      notifyError("Error adding subject");
      console.error("Error:", error);
    }
  };

  const fetchSubject = async () => {
    try {
      const response = await axios.get(
        "${process.env.REACT_APP_NODE_API}/class/subject"
      );
      setSubjects(response.data);
    } catch (error) {
      console.log("Error fetching Subject", error);
      notifyError("Error fetching Subject");
    }
  }

  const fetchClass = async () => {
    try {
      const response = await axios.get("${process.env.REACT_APP_NODE_API}/class");
      setClasses(response.data);
    } catch (error) {
      console.log("Error fetching Class", error);
      notifyError("Error fetching class");
    }
  };
  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <ToastContainer />
      <div className="flex-grow flex items-center justify-center bg-gray-100 py-10 px-4">
        <div className="container max-w-4xl bg-white shadow-lg rounded-lg p-8">
          <div className="mb-6 text-center">
            <h1 className="font-poppins text-4xl font-semibold text-blue-700">
              Add Subject
            </h1>
          </div>
          <form className="space-y-6" onSubmit={submit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col">
                <label className="block mb-2 text-md font-medium text-gray-900">
                  Class
                </label>
                <select
                  id="class"
                  required
                  value={cls}
                  onChange={(e) => setCls(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 dark:placeholder-gray-400 text-black"
                >
                  <option value="">Select Class</option>
                  {classes.map((className) => (
                    <option key={className.id} value={className.id}>
                      {className.class_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label className="block mb-2 text-md font-medium text-gray-900">
                  Subject Name
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-md rounded-lg block w-full p-2.5 focus:ring-blue-500 focus:border-blue-500"
                  value={subject_name}
                  onChange={(e) => setSubjectName(e.target.value)}
                  placeholder="Enter Subject Name"
                />
              </div>
            </div>
            <div className="text-center">
              <button
                className="focus:outline-none text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-md px-5 py-2.5"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
          <div className="mt-10">
            <DataTable
              className="z-0 bg-white rounded-lg shadow-md"
              columns={columns}
              data={subjects}
              pagination
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddCourse;
