import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaBook } from "react-icons/fa";
import axios from "axios";

const MCQ = () => {

  const [chapters, setChapters] = useState([]);
  useEffect(() => {
    AOS.init({ duration: "1000" });
  });
  const navigation = useNavigate();

  useEffect(() => {
    fetchChapters();
  }, []);

  const fetchChapters = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_NODE_API}/chapter/`);
      setChapters(response.data)
    } catch (error) {
      console.log('Error fetching Chapters', error);
    }
  }
  return (
    <div className="Admin">
      <Navbar />
      <div className="flex flex-col min-h-screen p-5 bg-gray-100">
        <h1 className="text-4xl font-bold text-center mb-10 text-gray-800">
          Select Chapter for MCQ
        </h1>
        <div className="flex flex-wrap justify-center gap-6 mt-6">
          {chapters.map((chapter) => (
            <div
              className="transition ease-in-out delay-150 max-w-sm w-full rounded-lg shadow-lg bg-gradient-to-r from-blue-400 to-blue-600 m-3 hover:from-blue-500 hover:to-blue-700 hover:scale-105 duration-200 cursor-pointer flex items-center justify-center"
              data-aos="fade-up"
              onClick={() => navigation("/Answer_MCQ")}
            >
              <div className="text-center p-6 flex gap-6">
                <FaBook className="text-white text-4xl mb-4" />
                <h5 className="text-2xl font-bold tracking-tight text-white">
                  {chapter.chapter_name} (MCQ)
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MCQ;
