import React from "react";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StudentMcqExam = () => {
  const notify = () => toast.success("MCQ Exam Completed");

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <ToastContainer />
      <div className="flex flex-col p-5">
        <div className="bg-white rounded-lg shadow-lg p-8 mt-6">
          <div className="mb-10">
            <h2 className="text-3xl font-bold text-center text-blue-700 mb-8">
              Chapter 1: Multiple Choice Questions
            </h2>
            <p className="text-lg text-gray-600 text-center">
              Review and understand the key concepts through these questions.
            </p>
          </div>
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-800">
              In Laravel, what is the purpose of a migration?
            </h3>
            <div className="ml-2 mt-3 space-y-2">
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question1-option1"
                  name="question1"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">A:</span> To create a new
                database table
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question1-option2"
                  name="question1"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">B:</span> To modify
                existing database tables
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question1-option3"
                  name="question1"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">C:</span> To seed the
                database with initial data
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question1-option4"
                  name="question1"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">D:</span> To create routes
                for the application
              </p>
            </div>
          </div>
          <hr className="border-t border-gray-200 my-8" />
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-800">
              Which directory contains the configuration files for a Laravel
              project?
            </h3>
            <div className="ml-2 mt-3 space-y-2">
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question2-option1"
                  name="question2"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">A:</span> app
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question2-option2"
                  name="question2"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">B:</span> config
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question2-option3"
                  name="question2"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">C:</span> resources
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question2-option4"
                  name="question2"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">D:</span> public
              </p>
            </div>
          </div>
          <hr className="border-t border-gray-200 my-8" />
          <div className="mb-8">
            <h3 className="text-xl font-semibold text-gray-800">
              What is the purpose of Laravel's Blade templating engine?
            </h3>
            <div className="ml-2 mt-3 space-y-2">
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question3-option1"
                  name="question3"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">A:</span> To handle routing
                within the application
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question3-option2"
                  name="question3"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">B:</span> To generate HTML
                responses to HTTP requests
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question3-option3"
                  name="question3"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">C:</span> To interact with
                the database
              </p>
              <p className="text-gray-700 flex items-center mx-5 bg-gray-100 p-3 rounded shadow-sm">
                <input
                  id="question3-option4"
                  name="question3"
                  type="radio"
                  className="mx-4 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                />
                <span className="mr-2 text-blue-500">D:</span> To simplify the
                creation of views and layouts
              </p>
            </div>
          </div>
          <div className="mx-auto flex justify-start items-start mt-10">
            <button
              onClick={notify}
              className="text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 shadow-lg transition duration-200 ease-in-out"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentMcqExam;
