import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../components/Navbar";
import "react-toastify/dist/ReactToastify.css";

const CreateBatch = () => {
  const notify = () =>
    toast.success("Batch Added Successfully");
  const [batch_name, setBatch_Name] = useState("");
  const [batch_code, setBatch_Code] = useState("");

  const submit = (e) => {
    e.preventDefault();
    notify();
  };

  const columns = [
    {
      name: "Batch Name",
      selector: (row) => row.batch_name,
      sortable: true,
    },
    {
      name: "Year",
      selector: (row) => row.batch_code,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex gap-2">
          <button className="text-white bg-red-500 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-md px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">
            Delete
          </button>
        </div>
      ),
    },
  ];

  const data = [
    {
      batch_name: "Batch 1",
      batch_code: "2022",
    },
  ];

  return (
    <div className="Admin min-h-screen">
      <Navbar />
      <div className="grid place-items-center bg-gray-100 py-10 px-4">
        <ToastContainer />
        <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
          <div className="p-5 text-center">
            <h1 className="font-poppins text-4xl font-semibold text-blue-700">
              Create Batch
            </h1>
          </div>
          <form onSubmit={submit} className="flex flex-col items-center gap-6">
            <div className="w-full flex flex-col md:flex-row gap-4">
              <div className="mb-6 w-full">
                <label
                  htmlFor="batch-name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Batch Name
                </label>
                <input
                  type="text"
                  id="batch-name"
                  value={batch_name}
                  onChange={(e) => setBatch_Name(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2.5 text-black"
                  placeholder="Enter Batch Name"
                />
              </div>
              <div className="mb-6 w-full">
                <label
                  htmlFor="batch-year"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Year
                </label>
                <input
                  type="text"
                  id="batch-year"
                  value={batch_code}
                  onChange={(e) => setBatch_Code(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-2.5 text-black"
                  placeholder="Enter Year"
                />
              </div>
            </div>
            <div className="text-center mt-6">
              <button
                className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-md px-5 py-2.5"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="container mt-10 bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
          <DataTable
            className="mx-auto z-0 border border-gray-300 rounded"
            columns={columns}
            data={data}
            fixedHeader
            pagination
            customStyles={{
              rows: {
                style: {
                  minHeight: "72px",
                },
              },
              headCells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  backgroundColor: "#f7fafc",
                },
              },
              cells: {
                style: {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateBatch;
