import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Course from '../assets/online_course.jpg'
import Exam from '../assets/exampage.jpg'
import Result from '../assets/exam_result.jpg'
import Progression from '../assets/Learning-Progress.png'
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from '../components/Navbar';

const StudentPanels = () => {
  useEffect(() => {
    AOS.init({ duration: "1000" });
  });
  const navigation = useNavigate();
  return (
    <div className="Admin">
      <Navbar />
      <div className="flex flex-col min-h-screen justify-center items-center">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 content-center">
          {/* <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover h-80 object-fit"
              src={Course}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                See Your Course
              </h5>

              <p class="mb-3 font-normal">
                {" "}
                In this Panel Give you the Courses you have enroll
              </p>
              <button
                onClick={() => navigation("/Student_Courses")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Click
              </button>
            </div>
          </div> */}

          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover h-80 object-fit"
              src={Progression}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Student Progression
              </h5>

              <p class="mb-3 font-normal">In this Panel Check Your Results</p>
              <button
                onClick={() => navigation("/Student_Progress")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Click
              </button>
            </div>
          </div>
          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover h-80 object-fit"
              src={Exam}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">Give Exam</h5>

              <p class="mb-3 font-normal">In this Panel Check your Marks</p>
              <button
                onClick={() => navigation("/exam_subjects")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Click
              </button>
            </div>
          </div>

          <div
            className="transition ease-in-out delay-150 max-w-lg rounded shadow-lg bg-blue-300 m-3 hover:scale-100 duration-200"
            data-aos="fade-up"
          >
            <img
              className="rounded-t-lg transition-all duration-300 rounded-lg cursor-pointer object-cover h-80 object-fit"
              src={Result}
              alt=""
            />

            <div className="p-5">
              <h5 className="mb-2 text-2xl font-bold tracking-tight ">
                Results
              </h5>

              <p class="mb-3 font-normal">In this Panel Check Your Results</p>
              <button
                onClick={() => navigation("/Student_Result")}
                class="w-full px-3 py-2 text-xl font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
              >
                Click
              </button>
            </div>
          </div>

          {/*        <div
                  onClick={() => navigation("/add-job")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Job Positions
                  </h2>
</div> */}

          {/*        <div
                  onClick={() => navigation("/add-skill")}
                  className="transition ease-in-out delay-150 max-w-lg w-full rounded shadow-lg bg-gray-300 m-3 hover:scale-110 duration-200"
                >
                  <h2 className="text-2xl text-center px-6 py-6">
                    Set Competency
                  </h2>
</div> */}
        </div>
      </div>
    </div>
  );
}

export default StudentPanels
